const { questionConditionJoinValues, questionConditionValues } = require("./questionnaireCommonValues")

function filterForLatestVersionKeyed(objects) {
    const tmp = {}

    if (objects) {
        for (let q of objects) {
            if (tmp[q.id]) {
                if (q.version > tmp[q.id].version) {
                    tmp[q.id] = q
                }
            } else {
                tmp[q.id] = q
            }
        }
    }

    return tmp
}

function filterForLatestVersion(objects) {
    const tmp = filterForLatestVersionKeyed(objects)
    return Object.values(tmp)
}

function groupBy(array, key) {
    return array.reduce(function (v, x) {
        (v[x[key]] = v[x[key]] || []).push(x)
        return v
    }, {})
}


function isQuestionRequired(question, questionsById, answersByQuestionId) {
    if (
        !question.ReferenceQuestionId
        && question.content.conditions
        && Array.isArray(question.content.conditions)
        && question.content.conditions.length
    ) {
        let evaluation = question.content.conditionJoinType == questionConditionJoinValues.ALL.value ? true : false
        for (const condition of question.content.conditions) {
            let currEval = true
            const conditionQuestion = questionsById[condition.question]
            const answer = answersByQuestionId[condition.question]

            if (!conditionQuestion) {
                currEval = true // broken conditon
            } else if (!answer || answer.content == undefined || answer.content == null) {
                currEval = false
            } else {
                // [null,null,null,null,null,5] => [5]
                const contentAsArray = (!Array.isArray(answer.content) ? [answer.content] : answer.content).filter(x => x !== null && x !== false)
                // filter out if conditions contains indices that have been removed from condition questions
                const conditionValues = condition.values.filter(x => x < conditionQuestion.content.options?.length)

                switch (condition.type) {
                    case questionConditionValues.NONE.value:
                        currEval = !contentAsArray.some((x) => conditionValues.includes(x))
                        break
                    case questionConditionValues.ANY.value:
                        currEval = contentAsArray.some((x) => conditionValues.includes(x))
                        break
                    case questionConditionValues.EXACT.value:
                        currEval = 0 == contentAsArray.filter((x) => !conditionValues.includes(x)).length && 0 == conditionValues.filter((x) => !contentAsArray.includes(x)).length
                        break
                }
            }

            if (question.content.conditionJoinType == questionConditionJoinValues.ALL.value) {
                evaluation &= currEval
                if (!evaluation) break
            } else {
                evaluation |= currEval
            }
        }

        return evaluation
    }

    return true
}

module.exports = {
    filterForLatestVersion,
    filterForLatestVersionKeyed,
    groupBy,
    isQuestionRequired
}