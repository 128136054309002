export default {
    organisationPageTitle(state) {
        return state.organizationStyle ? state.organizationStyle.pageTitle : '-'
    },

    organisationStyle(state) {
        return state.organizationStyle
    },

    organizationsLimited(state) {
        return state.organizationsLimited
    },

    fetchedOrganization(state) {
        return state.organization
    }
}
