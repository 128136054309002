const grantTable = {
    LOCATE_RISK_ADMIN: 0,
    ORGANIZATIONAL_ADMIN: 1,
    RESPONSIBLE_FOR_ALL_CUSTOMERS_OF_COMPANY: 10,
    RESPONSIBLE_FOR_GROUP_COMPANIES: 11,

    // Company
    ALL_COMPANY_GRANTS_ON_OWN_COMPANY: 100,
    ALL_COMPANY_GRANTS_ON_RESPONSIBLE_COMPANIES: 101,
    ALL_COMPANY_GRANTS_ON_SPECIFIC_COMPANY: 103,

    // User
    ALL_USER_GRANTS_ON_OWN_COMPANY: 200,
    ALL_USER_GRANTS_ON_RESPONSIBLE_COMPANIES: 201,
    ALL_USER_GRANTS_FOR_SELF: 202,

    // Task
    ALL_TASK_GRANTS_ON_OWN_COMPANY: 300,
    ALL_TASK_GRANTS_ON_SPECIFIC_TASK: 301,
    ALL_TASK_GRANTS_ON_RESPONSIBLE_COMPANIES: 302,
    CAN_VIEW_ALL_TASKS_FROM_OWN_COMPANY: 305,
    CAN_VIEW_ALL_TASKS_FROM_RESPONSIBLE_COMPANIES: 306,

    // Scan
    ALL_SCAN_GRANTS_ON_OWN_COMPANY: 400,
    ALL_SCAN_GRANTS_ON_SPECIFIC_SCAN: 401,
    ALL_SCAN_GRANTS_ON_RESPONSIBLE_COMPANIES: 402,

    // Management
    ALL_MGMT_GRANTS_ON_OWN_COMPANY: 500,
    ALL_MGMT_GRANTS_ON_RESPONSIBLE_COMPANIES: 502
}

module.exports = {
    grants: grantTable
}
