export default {
    pushRouteValue({commit}, value) {
        commit('pushRouteToHistory', value)
    },
    popRouteValue({state, commit}) {
        const arrayLength = state.routeFlowHistory.length
        if (arrayLength > 0) {
            const poppedValue = state.routeFlowHistory[arrayLength - 1]
            commit('popRouteFromHistory')
            return poppedValue
        } else {
            return null
        }
    }
}
