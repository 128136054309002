import axios from 'axios'
import Urls from './Urls'

function getApiInstance() {
    const instance = axios.create({
        baseURL: Urls.api,
        timeout: 1000 * 60 * 2, // 2 minutes
        // xsrfCookieName: enums.CSRFTokenName,
        // xsrfHeaderName: enums.CSRFTokenName,
        withCredentials: true
    })

    /*
    // logout/ delete all user information/cookies if response status is 401
    instance.interceptors.response.use(undefined, (err) => {
        if (
            err.response.status === 401 &&
            err.response.config.url !== '/auth/login' &&
            err.response.config.url !== '/auth/login/totp' &&
            // err.response.config.url !== '/users/report-activity' &&
            err.response.config &&
            !err.response.config.__isRetryRequest
        ) {
            window.location.reload(true)
        }
        throw err
    })
    */

    return instance
}

export const api = getApiInstance()
