const SubscriptionEvents = {
    ALL: 0,
    THIS: 1,
    RELEASE: 4
    /*
    EVENT: 2,
    TASK: 3
    */
}
Object.freeze(SubscriptionEvents)

module.exports = SubscriptionEvents
