<template>
    <v-tooltip bottom>
        <template v-slot:activator="{on, attrs}">
            <v-btn v-if="displayLRAdminElements" color="white" icon v-bind="attrs" v-on="on" @click="toggleDisplay">
                <v-icon> mdi-incognito </v-icon>
            </v-btn>
            <v-btn v-else color="white" icon v-bind="attrs" v-on="on" @click="toggleDisplay">
                <v-icon> mdi-incognito-off </v-icon>
            </v-btn>
        </template>
        <span>{{ displayLRAdminElements ? $t('lr-admin-button.tooltip.hide') : $t('lr-admin-button.tooltip.display') }}</span>
    </v-tooltip>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex'
import UserDialog from '@/views/users/UserDialog'

export default {
    name: 'LRAdminDisplayToggle',
    components: {UserDialog},
    data() {
        return {
            showUserDialog: false
        }
    },
    methods: {
        ...mapMutations(['toggleLRAdminElements']),
        toggleDisplay() {
            this.toggleLRAdminElements()
        }
    },
    computed: {
        ...mapGetters(['displayLRAdminElements'])
    }
}
</script>

<style scoped></style>
