<template>
    <v-dialog v-model="show" :max-width="options.width" :style="{zIndex: options.zIndex}" @keydown.esc="cancel" :persistent="options.persistent">
        <v-card outlined>
            <v-card-title class="text-h5"> {{ title }} </v-card-title>

            <v-card-text v-show="!!message">
                {{ message }}
            </v-card-text>

            <v-card-text v-show="!!options.storageKey">
                <label> <input type="checkbox" v-model="options.doNotShowAgain" /> {{ doNotShowAgainText }} </label>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn v-if="options.no" text @click="cancel" elevation="0"> {{ options.no }} </v-btn>

                <v-btn :color="options.color" @click="agree" elevation="0"> {{ options.yes }} </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// source: https://gist.github.com/eolant/ba0f8a5c9135d1a146e1db575276177d#file-confirm-vue-L8

export default {
    name: 'Confirm',
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
            color: 'primary',
            width: 400,
            zIndex: 200,
            yes: '',
            no: '',
            persistent: false,
            doNotShowAgain: false,
            storageKey: null
        },
        doNotShowAgainText: null
    }),
    computed: {
        show: {
            get() {
                return this.dialog
            },
            set(value) {
                this.dialog = value
                if (value === false) {
                    this.cancel()
                }
            }
        }
    },
    methods: {
        open(title, message, options) {
            this.dialog = true
            this.title = title
            this.message = message
            this.options.yes = options?.yes ?? this.$t('components.confirm.yes') // has to be initialized here because the component won't get recreated
            this.options.no = typeof options?.no != 'boolean' ? options?.no ?? this.$t('components.confirm.no') : null
            this.options.persistent = options?.persistent ?? this.options.persistent

            if (options?.key) {
                if (localStorage.getItem(options.key) === 'true') {
                    this.dialog = false
                    return Promise.resolve(false)
                }
                this.options.storageKey = options.key
                this.doNotShowAgainText = options?.doNotShowAgainText ?? this.$t('components.confirm.doNotShowAgain')
            }

            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        agree() {
            if (this.options.doNotShowAgain) {
                localStorage.setItem(this.options.storageKey, 'true')
            }
            this.resolve(true)
            this.dialog = false
        },
        cancel() {
            if (this.options.doNotShowAgain) {
                localStorage.setItem(this.options.storageKey, 'true')
            }
            this.resolve(false)
            this.dialog = false
        }
    }
}
</script>

<style></style>
