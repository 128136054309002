const apiUrl = process.env.VUE_APP_API_REST_URL
export default {
    api: apiUrl,
    sso: {
        oauth2: {
            google: (type, token) => {
                const tmpToken = token ? `&token=${token}` : ''
                return `${apiUrl}/auth/oauth2/google?type=${type}${tmpToken}`
            },
            microsoft: (type, token) => {
                const tmpToken = token ? `&token=${token}` : ''
                return `${apiUrl}/auth/oauth2/microsoft?type=${type}${tmpToken}`
            }
        }
    }
}
