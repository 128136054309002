export default {
    async deleteCyberriskAnalyses({dispatch}, cyberriskanalysisIds) {
        const params = new URLSearchParams()

        if (Array.isArray(cyberriskanalysisIds) && cyberriskanalysisIds.length > 0) {
            params.append('cyberriskanalyses', cyberriskanalysisIds.join(','))
        }

        return fetch(`${Window.$apiUrl}/api/v1/rest/products/cyberrisk-analyses/?${params.toString()}`, {
            method: 'DELETE',
            credentials: 'include'
        })
    },
    async rerateCyberriskAnalyses({dispatch}, cyberriskanalysisIds) {
        const params = new URLSearchParams()

        if (Array.isArray(cyberriskanalysisIds) && cyberriskanalysisIds.length > 0) {
            params.append('cyberriskanalyses', cyberriskanalysisIds.join(','))
        }

        return fetch(`${Window.$apiUrl}/api/v1/rest/products/cyberrisk-analyses/rerate/?${params.toString()}`, {
            method: 'PUT',
            credentials: 'include'
        })
    },
    async acceptCyberriskAnalyses({dispatch}, cyberriskanalysisIds) {
        const params = new URLSearchParams()

        if (Array.isArray(cyberriskanalysisIds) && cyberriskanalysisIds.length > 0) {
            params.append('cyberriskanalyses', cyberriskanalysisIds.join(','))
        }

        return fetch(`${Window.$apiUrl}/api/v1/rest/products/cyberrisk-analyses/accept/?${params.toString()}`, {
            method: 'PUT',
            credentials: 'include'
        })
    },
    async handleOrderCyberriskAnalysis({dispatch}, {cyberriskanalysisId, accept, reason, startAt, referenceCustomer, noReportPresentation}) {
        const params = new URLSearchParams()

        params.append('cyberriskanalysis', cyberriskanalysisId)
        params.append('accept', accept)
        params.append('startAt', startAt)
        params.append('referenceCustomer', referenceCustomer)
        params.append('noReportPresentation', noReportPresentation)

        //params.append('reason', reason)

        return fetch(`${Window.$apiUrl}/api/v1/rest/products/cyberrisk-analyses/handleOrder/?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({reason: reason})
        })
    },
    async refreshCyberriskAnalysis({dispatch}, {taskId, startAt, reason = ''}) {
        return fetch(`${Window.$apiUrl}/api/v1/rest/products/cyberrisk-analyses/${taskId}/refresh`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({reason: reason, startAt: startAt})
        })
    },
    async getCyberriskAnalysisNotifySubscriptions({dispatch}, {cyberriskanalysisId, groupId, withoutGroup}) {
        let group = ''
        if (groupId) {
            group = `&group_id=${groupId}`
        }
        return fetch(
            `${Window.$apiUrl}/api/v1/rest/products/cyberrisk-analyses/?includes=NOTIFY_SUBSCRIPTIONS&page=1&per_page=1&filter_id=${
                cyberriskanalysisId + group
            }&without_group=${withoutGroup}`,
            {
                method: 'GET',
                credentials: 'include'
            }
        )
    },
    async addNotifySubscriptionCyberriskAnalyses({dispatch}, {taskId, notifySubscriptions}) {
        return fetch(`${Window.$apiUrl}/api/v1/rest/products/cyberrisk-analyses/${taskId}/notify-subscriptions`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({NotifySubscriptions: notifySubscriptions})
        })
    },
    async updateNotifySubscriptionCyberriskAnalyses({dispatch}, {taskId, notifySubscriptions}) {
        return fetch(`${Window.$apiUrl}/api/v1/rest/products/cyberrisk-analyses/${taskId}/notify-subscriptions`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify({NotifySubscriptions: notifySubscriptions})
        })
    },
    async deleteNotifySubscriptionCyberriskAnalyses({dispatch}, {taskId, notifySubscriptionIds}) {
        return fetch(`${Window.$apiUrl}/api/v1/rest/products/cyberrisk-analyses/${taskId}/notify-subscriptions?id=${notifySubscriptionIds.join(',')}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            method: 'DELETE'
        })
    },
    async getCyberriskAnalyses({dispatch}, {page = undefined, perPage = undefined, includes, sort, filter}) {
        const params = new URLSearchParams()

        if (includes) {
            params.append('includes', includes.join(','))
        }
        if (page > 0) {
            params.append('page', page)
        }
        if (perPage > 0) {
            params.append('per_page', perPage)
        }
        if (Array.isArray(sort) && sort.length > 0) {
            params.append('sort', sort.flat().join(','))
        }
        if (Array.isArray(filter) && filter.length > 0) {
            for (const item of filter) {
                params.append(`filter_${item[0]}`, item[1])
            }
        }

        return fetch(`${Window.$apiUrl}/api/v1/rest/products/cyberrisk-analyses/?${params.toString()}`, {
            method: 'GET',
            credentials: 'include'
        })
    },
    async getScans({dispatch}, {cyberriskanalysisId, page = undefined, perPage = undefined, includes, sort}) {
        const params = new URLSearchParams()

        if (includes) {
            params.append('includes', includes.join(','))
        }
        if (page > 0) {
            params.append('page', page)
        }
        if (perPage > 0) {
            params.append('per_page', perPage)
        }
        if (Array.isArray(sort) && sort.length > 0) {
            params.append('sort', sort.flat().join(','))
        }

        return fetch(`${Window.$apiUrl}/api/v1/rest/products/cyberrisk-analyses/${cyberriskanalysisId}/scans?${params.toString()}`, {
            method: 'GET',
            credentials: 'include'
        })
    }
}
