const ScanState = {
    CREATE: 0,
    SCAN: 1,
    PARSE: 2,
    VALIDATE: 3,
    NOTIFY: 4,
    FINISH: 5,
    DELETE: 6,
    REPARSE: 7,
    ACCEPT: 10,
    OFFER: 11
}
Object.freeze(ScanState)

module.exports = ScanState
