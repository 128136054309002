export default {
    singleTourSteps(state) {
        if (state.tours.singleTourSteps) {
            return state.tours.singleTourSteps
        }
        return {}
    },

    awaitTourTimeoutId(state) {
        if (state.tours.awaitTourTimeoutId) {
            return state.tours.awaitTourTimeoutId
        }
        return 0
    },

    tours(state) {
        if (state.tours.tours) {
            return state.tours.tours
        }
        return {}
    },

    shouldShowTourFloatingBtn(state) {
        return getCurrentStepCount(state) > 0
    }
}

function getCurrentStepCount(state) {
    let numStepsToShow = 0

    // combine all steps
    let allSteps = Object.values(state.tours.tours)
        .map((tour) => tour.steps)
        .flat(1)
        .concat(state.tours.mountedSteps)

    // TODO maybe this can be placed somewhere else instead of state
    allSteps.forEach((step) => {
        // console.log(step);
        const target = typeof step.target === 'string' ? document.querySelector(step.target) : step.target
        if (target) {
            const rect = target.getBoundingClientRect()
            const testTarget = document.elementFromPoint(rect.x + rect.width / 2, rect.y + rect.height / 2)

            if (testTarget != null && rect != testTarget && !testTarget.contains(target) && !target.contains(testTarget)) {
                // element is hidden behind another -> do not show
                return
            }

            numStepsToShow++
        }
    })

    // console.log('count', numStepsToShow)
    return numStepsToShow
}
