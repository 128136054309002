export default {
    domainFilter(state) {
        if (state.taskFilter.domainFilter) {
            return state.taskFilter.domainFilter
        }
        return null
    },
    customerFilter(state) {
        if (state.taskFilter.customerFilter) {
            return state.taskFilter.customerFilter
        }
        return null
    },
    partnerFilter(state) {
        if (state.taskFilter.partnerFilter) {
            return state.taskFilter.partnerFilter
        }
        return null
    },
    distributorFilter(state) {
        if (state.taskFilter.distributorFilter) {
            return state.taskFilter.distributorFilter
        }
        return null
    },
    statusFilter(state) {
        if (state.taskFilter.statusFilter) {
            return state.taskFilter.statusFilter
        }
        return null
    },
    focusScanFilter(state) {
        if (state.taskFilter.focusScanFilter) {
            return state.taskFilter.focusScanFilter
        }
        return null
    },
    tableOptions(state) {
        return state.productFilter.options
    },

    vrmNameFilter(state) {
        if (state.vrmFilter.nameFilter) {
            return state.vrmFilter.nameFilter
        }
        return null
    },
    vrmCustomerFilter(state) {
        if (state.vrmFilter.customerFilter) {
            return state.vrmFilter.customerFilter
        }
        return null
    },
    vrmPartnerFilter(state) {
        if (state.vrmFilter.partnerFilter) {
            return state.vrmFilter.partnerFilter
        }
        return null
    },
    vrmDistributorFilter(state) {
        if (state.vrmFilter.distributorFilter) {
            return state.vrmFilter.distributorFilter
        }
        return null
    }
}
