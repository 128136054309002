export default {

  getCurrentContingentOfProduct: (state) => (productName) => {
    if (state.organization && state.organization.pricing) {
      const pricing = state.organization.pricing
      if (pricing.contingent && pricing.contingent[productName]) {
        return pricing.contingent[productName]
      }
    }
    return ''
  }

}
