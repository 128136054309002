import i18next from 'i18next'
import Actions from '../../../../models/models/enums/ACTIONS'
import $vue from '../../main'
import {setLoggedIn} from '../../new/helper/Auth'

export default {
    async changeLanguage({state, dispatch, commit}, lang) {
        if (state.userData) {
            const response = await fetch(`${Window.$apiUrl}/api/rest/user/${state.userData.id}/change-language`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({language: lang})
            })
            if (response.ok) {
                const locale = await response.text()
                await i18next.changeLanguage(locale)
                $vue.$vuetify.lang.current = locale
                const userData = state.userData
                userData.language = lang

                commit('setUserData', userData)
            }
        } else {
            await i18next.changeLanguage(lang)
            $vue.$vuetify.lang.current = lang
            commit('setLanguage', lang)
        }
        /*
        setCookie('i18next', lang, 30)

        function setCookie(cname, cvalue, exdays = 0) {
            const d = new Date()
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
            const expires = 'expires=' + d.toUTCString()
            document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
        }
        */
    },

    showPermanentHelp({state, dispatch, commit}) {
        dispatch('showAllTourStepsInUI')
    },

    async loadUserGrants({dispatch, commit}, userId) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/user/${userId}/grants`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const userGrants = await response.json()
            commit('setUserGrants', userGrants)
            return userGrants
        }
    },

    async deleteRole({state, dispatch}, roleId) {
        await fetch(`${Window.$apiUrl}/api/rest/user/role/${roleId}/delete`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include'
        })
        dispatch('loadRoles')
    },

    async createUser({dispatch}, user) {
        return fetch(`${Window.$apiUrl}/api/rest/user/create`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(user)
        })
    },

    async updateUser({state, dispatch, commit}, user) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/user/update`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(user)
        })
        if (response.ok) {
            if (state.userData?.id === user?.id) {
                const userData = {...state.userData, ...user}
                commit('setUserData', userData)
            }
        }
    },

    async loadUsers({dispatch, commit}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/user/users`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const users = await response.json()
            commit('setUsers', users)
        }
    },

    async deleteUser({state, dispatch}, userId) {
        await fetch(`${Window.$apiUrl}/api/rest/user/${userId}/delete`, {
            method: 'POST',
            credentials: 'include'
        })
        dispatch('loadUsers')
    },

    async [Actions.USER_CHANGE_PASSWORD]({state, dispatch}, payload) {
        return await fetch(`${Window.$apiUrl}/api/rest/user/${state.userData.id}/change-password`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
    },

    async getSharedOrganizationsFromUser({}, userId) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/user/${userId}/shared-organizations`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const users = await response.json()
            return users
        }
    },

    async setSharedOrganizationToUser({}, params) {
        if (params.userId && params.companyId && params.grants) {
            await fetch(`${Window.$apiUrl}/api/rest/user/shared-organizations/share`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    userId: params.userId,
                    companyId: params.companyId,
                    grants: params.grants
                })
            })
        }
    },

    async renewAPIToken({}, userId) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/user/${userId}/api-token/renew`, {
            method: 'POST',
            credentials: 'include'
        })
        if (response.ok) {
            const reponse = await response.json()
            return reponse.token
        }
        return false
    },

    async getAPIToken({}, userId) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/user/${userId}/api-token/`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const reponse = await response.json()
            return reponse.token
        }
        return false
    },

    async updateToken({state, commit}) {
        try {
            const response = await fetch(`${Window.$apiUrl}/api/rest/user/update-token/${Date.now()}`, {
                method: 'PUT',
                credentials: 'include'
            })
            if (response.ok) {
                setLoggedIn()
                return true
            }
        } catch (err) {}

        return false
    }
}
