const enums = {
    Actions: require('./ACTIONS'),
    Grants: require('./GRANTS'),
    GrantTypes: require('./GrantTypes'),
    IntervalTypes: require('./IntervalTypes'),
    Languages: require('./Languages'),
    LogAction: require('./LogAction'),
    NotifyEvents: require('./NotifyEvents'),
    PartnerLevel: require('./PartnerLevel'),
    ProductTypes: require('./ProductTypes'),
    ReversedIntervalTypes: require('./ReversedIntervalTypes'),
    ReversedNotifyEvents: require('./ReversedNotifyEvents'),
    ReversedProductTypes: require('./ReversedProductTypes'),
    ReversedScanProgresses: require('./ReversedScanProgresses'),
    ReversedScanStates: require('./ReversedScanStates'),
    ScanProgress: require('./ScanProgress'),
    ScanState: require('./ScanState'),
    SubscriptionEvents: require('./SubscriptionEvents'),
    TargetItemExpressions: require('./TargetItemExpressions'),
    TargetItemType: require('./TargetItemType'),
    ViewDetails: require('./ViewDetails'),
    FilterGroups: require('./FilterGroups'),
    NewProductTypes: {
        MANAGEMENTÜBERSICHT: 0,
        SCHWACHSTELLENANALYSE: 1,
        SICHERHEITSVERGLEICH: 2,
        INVENTARISIERUNG: 3
    },
    DealReg: {
        REQUESTED: 'REQUESTED',
        REJECTED: 'REJECTED'
    },
    OAuth2Types: require('./OAuth2Types'),
    OAuth2Provider: require('./OAuth2Provider'),
    reverse: reverse,
    industryTypes: require('./IndustryTypes'),
    ratingCategories: require('./RatingCategories'),
    AuthMethodTypes: require('./AuthMethodTypes'),
    QuestionnaireStates: require('./QuestionnaireStates'),
    CriticalityTypes: require('./CriticalityTypes')
}

function reverse(enumObj) {
    let Reversed = {}
    Object.keys(enumObj).forEach((key) => (Reversed[enumObj[key]] = key))
    return Reversed
}
//Object.freeze(enums)

module.exports = enums
