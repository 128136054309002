export default {
    questionnaire(state) {
        return state.questionnaire
    },

    questionnaireNameFilter(state) {
        return state.questionnaireListFilters.nameFilter
    },

    questionnaireOwnerFilter(state) {
        return state.questionnaireListFilters.ownerFilter
    },

    answeringListFilters(state) {
        return state.answeringListFilters
    },

    questionnaireAnsweringList(state) {
        return state.questionnaireAnsweringList
    },

    pendingAnswerings(state) {
        return state.pendingAnswerings
    },

    questionnaires(state) {
        return state.questionnaires
    }
}
