import GrantTypes from '../../../../models/models/enums/GrantTypes'
import {TargetObjectType, TargetObjectExpressions} from '../../../../models/models/enums/'

import PartnerLevel from '../../../../models/models/enums/PartnerLevel'
import vue from '../../main'
import ENUMS from '../../../../models/models/enums'

function formatArray(values, enumKey) {
    return values.map((value) => ({
        value: value,
        text: vue.$t(`enums.${enumKey}.${value}`)
    }))
}

export default {
    formattedGrantTypes() {
        return formatArray(Object.values(GrantTypes), 'grantType')
    },

    formattedTargetItemTypes() {
        return formatArray(Object.values(TargetObjectType), 'targetItemType')
    },

    formattedTargetItemExpressions() {
        return formatArray(Object.values(TargetObjectExpressions), 'targetItemExpression')
    },

    intervalValues() {
        return [
            {
                text: vue.$t('createTask.interval.daily'),
                value: 24
            },
            {
                text: vue.$t('createTask.interval.monthly'),
                value: 720
            },
            {
                text: vue.$t('createTask.interval.quarter'),
                value: 2160
            }
        ]
    },

    partnerLevels() {
        return [
            {id: 'XS', text: 'Level XS: 20%'},
            {id: 'S', text: 'Level S: 25%'},
            {id: 'M', text: 'Level M: 30%'},
            {id: 'L', text: 'Level L: 35%'},
            {id: 'XL', text: 'Level XL: 40%'},
            {id: 'XXL', text: 'Level XXL: 45%'},
            {id: 'MAX', text: 'Level MAX: 50%'},
            {id: 'S_DISTRIBUTOR', text: 'Distributor S: 35%'},
            {id: 'M_DISTRIBUTOR', text: 'Distributor M: 37.5%'},
            {id: 'L_DISTRIBUTOR', text: 'Distributor L: 40%'}
        ]
    }
}
