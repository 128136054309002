import Vue from 'vue'

export default {
    setProductTasks(state, products) {
        Vue.set(state, 'productTasks', products)
    },
    setTasksOfRiskList(state, tasks) {
        Vue.set(state, 'riskListTasks', tasks)
    },
    setProductTypes(state, products) {
        Vue.set(state, 'productTypes', products)
    },
    setProductsSubscription(state, subscription) {
        Vue.set(state, 'productsSubscription', subscription)
    },
    setUpdatedTasks(state) {
        Vue.set(state, 'updatedTasks', Math.random())
    },
    setUpdatedVendorRiskManagement(state) {
        Vue.set(state, 'updatedVendorRiskManagement', Math.random())
    },
    setUpdatedSecurityBenchmarks(state) {
        Vue.set(state, 'updatedSecurityBenchmarks', Math.random())
    },
    setUpdatedSharedScans(state) {
        Vue.set(state, 'updatedSharedScans', Math.random())
    },
    setUpdatedDealRegs(state) {
        Vue.set(state, 'updatedDealRegs', Math.random())
    },
    setDomainSearch(state, search) {
        Vue.set(state.productFilter, 'domainSearch', search || '')
    },
    setTableOptions(state, options) {
        if (typeof options === 'object') {
            Vue.set(state.productFilter, 'options', options)
        }
    },
    toggleProductFilter(state, {filter, value}) {
        const isPresent = state.productFilter[filter].includes(value)

        if (isPresent) {
            Vue.set(
                state.productFilter,
                filter,
                state.productFilter[filter].filter((_value) => _value !== value)
            )
        } else {
            Vue.set(state.productFilter, filter, [...state.productFilter[filter], value])
        }
    },

    setProductTypeFilter(state, value) {
        Vue.set(state.productFilter, 'productType', value || '')
    },

    setRiskManagementLists(state, vendorRiskManagement) {
        Vue.set(state, 'vendorRiskManagement', vendorRiskManagement)
    },

    setRiskComparisionLists(state, riskComparision) {
        Vue.set(state, 'riskComparision', riskComparision)
    },

    setDomainFilter(state, filter) {
        Vue.set(state.taskFilter, 'domainFilter', filter)
    },

    setCustomerFilter(state, filter) {
        Vue.set(state.taskFilter, 'customerFilter', filter)
    },

    setPartnerFilter(state, filter) {
        Vue.set(state.taskFilter, 'partnerFilter', filter)
    },

    setDistributorFilter(state, filter) {
        Vue.set(state.taskFilter, 'distributorFilter', filter)
    },

    setStatusFilter(state, filter) {
        Vue.set(state.taskFilter, 'statusFilter', filter)
    },

    setFocusScanFilter(state, filter) {
        Vue.set(state.taskFilter, 'focusScanFilter', filter)
    },

    setVRMNameFilter(state, filter) {
        Vue.set(state.vrmFilter, 'nameFilter', filter)
    },

    setVRMCustomerFilter(state, filter) {
        Vue.set(state.vrmFilter, 'customerFilter', filter)
    },

    setVRMPartnerFilter(state, filter) {
        Vue.set(state.vrmFilter, 'partnerFilter', filter)
    },

    setVRMDistributorFilter(state, filter) {
        Vue.set(state.vrmFilter, 'distributorFilter', filter)
    }
}
