export const CyberRiskAnalysisTour = {
    tourName: 'CyberRiskAnalysisTour',
    requiresElement: '.guided-tour-cyber-risk-analysis-report-details-button',
    steps: [
        {
            target: '.guided-tour-cyber-risk-analysis-h1', // We're using document.querySelector() under the hood
            content: 'new.translation.views.widget.overviewp_welcome_1.content',
            //brauchen kein wait-for weil die tour erst startet wenns da ist
            /*
            header: {
                title: 'new.translation.views.widget.overviewp_welcome_1.title'
            },
            */
            padding: true
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-details-button',
            content: 'new.translation.views.widget.overviewp_scan_selection_2.content'
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-h1',
            content: 'new.translation.views.widget.scanp_header_3.content',
            navigate: '.guided-tour-cyber-risk-analysis-report-details-button',
            navigateBack: '.guided-tour-cyber-risk-analysis-report-h1 button',
            padding: true
            //brauchen kein waitFor da er wegen der naviagtion eh wartet
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-domain-info-card',
            content: 'new.translation.views.widget.scanp_systems_findings_overview_4.content'
            //waitFor: -1 //4ever!
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-scan-info-card-app',
            content: 'new.translation.views.widget.scanp_categories_overview_5.content'
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-score-overview',
            content: 'new.translation.views.widget.scanp_score_overview_6.content'
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-domain-card',
            content: 'new.translation.views.widget.scanp_systems_list_7.content'
        },

        {
            target: '.guided-tour-cyber-risk-analysis-config',
            content: 'new.translation.views.widget.scanp_exclude_include_systems_26.content'
            //   click: [{target: '.dialog button.primary--text'}] // close on backwards, REMOVED BECAUSE OF ISSUES WITH NAVIGATION & waitFor Timeout
        },
        {
            target: '.guided-tour-config-domains-expanded',
            inDialog: true,
            content: 'new.translation.views.widget.scanp_exclude_include_systems_select_27.content',
            // opens config then opens expand, target will wait up to 5 seconds to appear
            click: [
                //       {target: '.guided-tour-cyber-risk-analysis-config', required: true},    REMOVED BECAUSE OF ISSUES WITH NAVIGATION & waitFor Timeout
                //       {target: '.guided-tour-config-domains', required: true}     REMOVED BECAUSE OF ISSUES WITH NAVIGATION & waitFor Timeout
            ]
            //    waitFor: 500 // each click    REMOVED BECAUSE OF ISSUES WITH NAVIGATION & waitFor Timeout
            // TODO when such a condition is at the step, it will glitch with permanent help, because it will close opened expander
            // this step per se makes some problems with permanent help as it is in expanded container
            // no help step will be visible, when the step i hidden in unexpanded state
            // but it will also think there are some steps so will not show toast that there is no element
        },
        {
            target: '.report-page .guided-tour-finding-list',
            content: 'new.translation.views.widget.scanp_vulnerabilitiy_list_8.content',
            click: '.dialog button.primary--text' // close config dialog
        },
        {
            target: '.report-page .guided-tour-finding-list-id',
            content: 'new.translation.views.widget.scanp_vulnerability_title_9.content',
            click: '.guided-tour-vulnerability-list-click-target',
            clickOnce: true
        },
        {
            target: '.report-page .guided-tour-finding-list-affected-systems',
            content: 'new.translation.views.widget.scanp_vulnerability_affected_systems_10.content'
        },
        {
            target: '.report-page .guided-tour-finding-list-description',
            content: 'new.translation.views.widget.scanp_vulnerability_description_11.content',
            padding: true,
            click: '.dialog button.primary--text' // click because when navigating back
        },
        {
            target: '.report-page .guided-tour-finding-list button.mdi-cog',
            content: 'new.translation.views.widget.scanp_vulnerability_configuration_12.content',
            click: '.guided-tour-finding-list button.mdi-cog',
            inDialog: true,
            padding: true
        },

        /*
        NOTE: steps are added when ConfigDialog.vue is shown !
        alt solution:
        {
            target: '.guided-tour-config-dialog-fp',
            content: 'new.translation.views.widget.scanp_vulnerability_configuration_falsepositive_13.content'
        },
        {
            target: '.guided-tour-config-dialog-criticality',
            content: 'new.translation.views.widget.scanp_vulnerability_configuration_criticality_14.content'
        },
        {
            target: '.guided-tour-config-dialog-notes',
            content: 'new.translation.views.widget.scanp_vulnerability_configuration_notes_15.content',
            before: async value => {
                if (value == 'previous') {
                    // we have to make sure, that the dialog is visble when navigating back
                    const target = document.querySelector('.guided-tour-finding-list button.mdi-cog');
                    target.click();
                    await delay(250);
                }
            }
        },*/
        {
            target: '.guided-tour-cyber-risk-analysis-report-host-list',
            content: 'new.translation.views.widget.scanp_system_vulnerability_16.content',
            click: [
                {target: '.guided-tour-vulnerability-list-click-target', once: true}, // make sure list is opened
                {target: '.dialog button.primary--text'}
            ]
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-host-list-entry',
            content: 'new.translation.views.widget.scanp_system_details_17.content',
            click: '.guided-tour-cyber-risk-analysis-report-host-list-entry',
            clickOnce: true
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-host-list-entry-domains',
            content: 'new.translation.views.widget.scanp_system_details_domains_18.content',
            padding: true
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-host-list-entry-network',
            content: 'new.translation.views.widget.scanp_system_details_host_connection_19.content',
            click: '.guided-tour-cyber-risk-analysis-report-host-list-entry-network .v-expansion-panel-header',
            clickOnce: true,
            padding: true
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-host-list-entry-finding-list',
            content: 'new.translation.views.widget.scanp_system_details_vulnerabilities_list_20.content'
        },

        {
            target: '.guided-tour-cyber-risk-analysis-report-host-list-entry-finding-list .findingRow[data-id="4373607310803684"]',
            content: 'new.translation.views.widget.scanp_additional_applications_22.content'
        },
        /*
       netzwerkgraph lädt lange, lassen ihn weg.
       {
            target: '.guided-tour-cyber-risk-analysis-report-graph',
            content: 'new.translation.views.widget.scanp_network_graph_21.content', //removed, brauchen das popup hier nicht popup beim kompletten netzwerkgraphen, potentiell laaangsam
            click: '.guided-tour-cyber-risk-analysis-report-graph button',
            clickOnce: true,
            popInner: true
        },*/

        {
            target: '.guided-tour-tag-filter-dialog-button',
            content: 'new.translation.views.widget.scanp_filters_28.content',
            click: '.guided-tour-cyber-risk-analysis-report-host-list-entry',
            clickOnce: true,
            clickRequired: true
        },
        {
            target: 'button.guided-tour-icon-bar-download',
            content: 'new.translation.views.widget.scanp_downloads_29.content',
            click: '.dialog .mdi-close'
        },
        {
            target: '.dialog .v-list.guided-tour-download-list',
            content: 'new.translation.views.widget.scanp_downloads_formats_30.content',
            click: '.guided-tour-icon-bar-download',
            inDialog: true,
            popInner: true
            /*clickDelay: 100 // dialogs are a little slower to open*/
        },
        {
            target: 'button.guided-tour-filter-tags-page-button',
            content: 'new.translation.views.widget.filter_page_button.content'
        },
        {
            target: 'button.guided-tour-filter-tags-page-button',
            content: 'new.translation.views.widget.filter_page_button.content',
            navigate: '.guided-tour-filter-tags-page-button',
            navigateBack: '.filter-page .guided-tour-filter-tags-page-h1 button',
            padding: true
        },
        {
            target: '.filter-page .guided-tour-filter-tags-page-h1',
            content: 'new.translation.views.widget.scanp_header_3.content',
            padding: true
        },
        {
            target: '.filter-page .guided-tour-create-new-filter-button',
            content: 'new.translation.views.widget.create_new_filter.content',
            padding: true
        },
        {
            target: '.filter-page .guided-tour-create-new-filter-button',
            content: 'new.translation.views.widget.filter_page_button.content',
            navigate: '.guided-tour-create-new-filter-button',
            navigateBack: '.filter-page .guided-tour-create-new-filter-with-temlpate-links',
            padding: true
        },
        {
            target: '.create-filter-page .guided-tour-tag-filter-name-input',
            content: 'new.translation.views.widget.filter_name.content',
            navigateBack: '.create-filter-page .guided-tour-create-filter-page-h1 button',
            padding: true
        },
        {
            target: '.create-filter-page .guided-tour-tag-filter-save-button',
            content: 'new.translation.views.widget.save_filter.content',
            padding: true
        },
        {
            target: '.create-filter-page .guided-tour-create-filter-page-h1 button',
            content: 'new.translation.views.widget.back_to_filters_page.content',
            navigate: '.create-filter-page .guided-tour-create-filter-page-h1 button',
            navigateBack: '.guided-tour-create-new-filter-button',
            inDialog: true
        },
        {
            target: '.filter-page .guided-tour-create-new-filter-with-temlpate-links',
            content: 'new.translation.views.widget.create_new_filter_with_template.content',
            padding: true
        },
        {
            target: '.filter-page .guided-tour-filter-page-apply-button',
            content: 'new.translation.views.widget.apply_filter.content',
            padding: true
        },
        {
            target: '.filter-page .guided-tour-filter-page-share-button',
            content: 'new.translation.views.widget.share_filter.content',
            padding: true
        },
        {
            target: '.filter-page .guided-tour-filter-page-download-button',
            content: 'new.translation.views.widget.download_filter.content',
            padding: true
        },
        {
            target: '.filter-page .guided-tour-filter-tags-page-h1 button',
            content: 'new.translation.views.widget.back_to_report_page.content'
        },
        // TODO when read only does not know that the nxt step is not available and fails
        // refresh makes the site available again
        {
            target: '.filter-page .guided-tour-filter-tags-page-h1 button',
            content: 'new.translation.views.widget.back_to_report_page.content',
            navigate: '.filter-page .guided-tour-filter-tags-page-h1 button',
            navigateBack: '.guided-tour-filter-tags-page-button',
            inDialog: true
        }, // Dialog opens another
        // workaround step to allow navigating backwards which requires an element to be visible
        // also less chaos in navigation (less surprises for then user)

        // den krams haben wir nach hinten geshcoben
        {
            target: '.guided-tour-cyber-risk-analysis-report-h1 button',
            content: 'new.translation.views.widget.scanp_scroll_to_top_23.content'
        },
        // TODO when read only does not know that the nxt step is not available and fails
        // refresh makes the site available again
        {
            target: '.guided-tour-cyber-risk-analysis-notifications',
            content: 'new.translation.views.widget.scanp_monitoring_light_24.content',
            navigate: '.guided-tour-cyber-risk-analysis-report-h1 button',
            navigateBack: '.guided-tour-cyber-risk-analysis-report-details-button',
            click: [{target: '.dialog .mdi-close'}, {target: '.guided-tour-cyber-risk-analysis-notifications'}],
            inDialog: true
        } // Dialog opens another

        /*
       ist in dem dialog hardcoded, da conditional für !isMGMT
       {
            target: '.guided-tour-cyber-risk-analysis-notifications-light',
            content: 'new.translation.views.widget.scanp_monitoring_light_activation_25.content',
            click: '.guided-tour-cyber-risk-analysis-notifications'
        }*/
    ]
}
