import Cookies from 'js-cookie'

export function isLoggedIn() {
    return !!Cookies.get('quail')
}

export function setLoggedIn() {
    Cookies.set('quail', (Math.random() + 1).toString(36).substring(2), {sameSite: 'lax', secure: true})
}

export function removeLoggedIn() {
    Cookies.remove('quail')
}
