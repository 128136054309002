import Vue from 'vue'

export default {
  setShowShareDialog (state, { show, type, itemId }) {
    Vue.set(state.shareDialog, 'show', show)
    Vue.set(state.shareDialog, 'type', type)
    Vue.set(state.shareDialog, 'itemId', itemId)
  }

}
