const ratingCategories = {
    APP: 'app',
    INFECTION: 'infection',
    SSL: 'ssl',
    NETWORK: 'network',
    DNS: 'dns',
    WEB: 'web',
    DSGVO: 'dsgvo',
    BREACH: 'breach',
    DDOS: 'ddos'
}

module.exports = ratingCategories
