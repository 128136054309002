export default {
    async validateScans({dispatch}, scanIds) {
        const params = new URLSearchParams()

        if (Array.isArray(scanIds) && scanIds.length > 0) {
            params.append('scans', scanIds.join(','))
        }

        return fetch(`${Window.$apiUrl}/api/v1/rest/scans/validate/?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            credentials: 'include'
        })
    },

    async rerateScans({dispatch}, {scanIds, andYounger = false, ignoreLock = false}) {
        const params = new URLSearchParams()

        if (Array.isArray(scanIds) && scanIds.length > 0) {
            params.append('scans', scanIds.join(','))
        }

        return fetch(`${Window.$apiUrl}/api/v1/rest/scans/rerate/?andYounger=${andYounger}&ignoreLock=${ignoreLock}&${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            credentials: 'include'
        })
    },

    async deleteScans({dispatch}, scanIds) {
        const params = new URLSearchParams()

        if (Array.isArray(scanIds) && scanIds.length > 0) {
            params.append('scans', scanIds.join(','))
        }

        return fetch(`${Window.$apiUrl}/api/v1/rest/scans/?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            credentials: 'include'
        })
    },

    async getNessusImportFiles({dispatch}, scanId) {
        return fetch(`${Window.$apiUrl}/api/v1/rest/scans/nessus/${scanId}`, {
            method: 'GET',
            credentials: 'include'
        })
    },
    async uploadNessusData({dispatch}, {scanId, nessusData}) {
        const formData = new FormData()
        formData.append('nessusData', nessusData)

        await fetch(`${Window.$apiUrl}/api/v1/rest/scans/nessus/${scanId}/upload`, {
            method: 'PUT',
            credentials: 'include',
            isFile: true,
            body: formData
        })
    },
    async deleteNessusData({dispatch}, {scanId, externalScanResultId}) {
        return fetch(`${Window.$apiUrl}/api/v1/rest/scans/nessus/${scanId}/${externalScanResultId}`, {
            method: 'DELETE',
            credentials: 'include'
        })
    }
}
