export default {
    async createSharedLink({state, dispatch, commit}, data) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/shared/create-shared-link`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data)
        })
        if (!response.ok) {
            throw new Error()
        }
        return await response.json()
    },

    async createAndEmailSharedLink({state, dispatch, commit}, data) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/shared/create-shared-link-and-email`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data)
        })
        if (!response.ok) {
            throw new Error()
        }
        return await response.json()
    },

    async updateSharedLink({state, dispatch, commit}, data) {
        // console.log(data)
        const response = await fetch(`${Window.$apiUrl}/api/rest/shared/update-shared-link/${data.id}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data)
        })
        if (!response.ok) {
            throw new Error()
        }
        return await response.json()
    },

    async deleteSharedLink({dispatch}, linkId) {
        await fetch(`${Window.$apiUrl}/api/rest/shared/${linkId}/delete`, {
            method: 'POST',
            credentials: 'include'
        })
    },

    async shareItem({state, dispatch}, data) {
        if ((data.itemId, data.type, data.userId)) {
            const response = await fetch(`${Window.$apiUrl}/api/rest/shared/share-link`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(data)
            })
            if (!response.ok) {
                throw new Error()
            }
            return true
        }
        return false
    },

    async unshareItem({dispatch}, data) {
        if ((data.itemId, data.type, data.userId)) {
            const response = await fetch(`${Window.$apiUrl}/api/rest/shared/unshare-link`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(data)
            })
            if (!response.ok) {
                throw new Error()
            }
            return true
        }
        return false
    },

    async doesItemNeedPassword({state, dispatch}, linkId) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/public/shared/${linkId}/needs-password`, {
            method: 'POST',
            credentials: 'include'
        })
        if (response.ok) {
            return await response.text()
        } else {
            return null
        }
    },

    async loadSharedLinks({state, dispatch}, data) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/shared/${data.itemId}/${data.type}/${data.groupId ?? false}`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const json = await response.json()
            return json
        }
        return []
    },

    async loadSharedUsers({state, dispatch}, data) {
        const groupId = data.groupId ? data.groupId : ''
        const response = await fetch(`${Window.$apiUrl}/api/rest/shared/loadSharedUsers/${data.itemId}/${data.type}/${groupId}`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const json = await response.json()
            return json
        }
        return []
    },

    async loadSharedItem({state, dispatch, commit}, {linkId, password}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/public/shared/load-item/`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({linkId, password})
        })
        if (response.ok) {
            const item = await response.json()
            if (item.mailContact) {
                commit('setMailContact', item.mailContact)
            }
            return {item, expires: response.headers.get('Expires')}
        }
        return {
            error: true,
            rateLimit: response.status === 429
        }
    },

    async loadCompanyIdFromSharedLink({state, dispatch, commit}, linkId) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/public/shared/${linkId}/companyId`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const item = await response.json()
            return item.id
        }
        return null
    },

    async loadSharedScans({}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/shared/shared-scans`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const scans = await response.json()
            return scans
        }
        return null
    },

    async loadSharedScanFilters({}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/shared/shared-scan-filters`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const scans = await response.json()
            return scans
        }
        return null
    },

    async loadAllSharedLinks({}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/shared/all-shared-links`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const links = await response.json()
            return links
        }
        return null
    }
}
