import Vue from 'vue'
import Vuex from 'vuex'
import taskActions from './actions/taskActions'
import taskMutations from './mutations/taskMutations'
import productActions from './actions/productActions'
import dealRegActions from './actions/dealRegActions'
import productMutations from './mutations/productMutations'
import authActions from './actions/authActions'
import authMutations from './mutations/authMutations'
import restActions from './actions/restActions'
import displayGetters from './getters/displayGetters'
import organizationMutations from './mutations/organizationMutations'
import organizationActions from './actions/organizationActions'
import enumGetters from './getters/enumGetters'
import userMutations from './mutations/userMutations'
import userActions from './actions/userActions'
import authGetters from './getters/authGetters'
import completeScanHTMLGetters from './getters/completeScanHTMLGetters'
import roleGetters from './getters/roleGetters'
import generalGetters from './getters/generalGetters'
import configActions from './actions/configActions'
import configMutations from './mutations/configMutations'
import sharedLinkActions from '@/store/actions/sharedLinkActions'
import sharedLinkMutations from '@/store/mutations/sharedLinkMutations'
import validationGetters from '@/store/getters/validationGetters'
import errorMutations from './mutations/errorMutations'
import pricingActions from '@/store/actions/pricingActions'
import pricingMutations from '@/store/mutations/pricingMutations'
import generalMutations from './mutations/generalMutations'
import pricingGetters from '@/store/getters/pricingGetters'
import productGetters from '@/store/getters/productGetters'
import organizationGetters from '@/store/getters/organizationGetters'
import configGetters from './getters/configGetters'
import ScanActions from './actions/ScanActions'
import CyberriskAnalysisActions from './actions/CyberriskAnalysisActions'
import CyberriskComparisonActions from './actions/CyberriskComparisonActions'
import tourGetters from './getters/tourGetters'
import tourMutations from './mutations/tourMutations'
import tourActions from './actions/tourActions'
import scanGetters from './getters/scanGetters'
import scanMutations from './mutations/scanMutations'
import questionnaireGetters from './getters/questionnaireGetters'
import questionnaireMutations from './mutations/questionnaireMutations'
import questionnaireActions from './actions/questionnaireActions'
import routeFlowMutations from './mutations/routeFlowMutations'
import routeFlowActions from './actions/routeFlowActions'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        showDefaultError: false,
        showQuestionnaire: false,
        shareDialog: {
            show: false,
            type: null,
            itemId: null
        },
        language: 'de',
        userData: '',
        currentScan: null,
        mfa: false,
        userGrants: null,
        ownGrants: null,
        productsSubscription: null,
        productFilter: {
            domainSearch: '',
            status: '',
            productType: '',
            options: {
                page: 1,
                itemsPerPage: 15,
                sortBy: ['lastFinishedScan'],
                sortDesc: [true],
                groupBy: [],
                groupDesc: [],
                multiSort: false,
                mustSort: true
            }
        },
        taskFilter: {
            domainFilter: null,
            customerFilter: null,
            partnerFilter: null,
            distributorFilter: null,
            statusFilter: null,
            focusScanFilter: null
        },
        vrmFilter: {
            nameFilter: null,
            customerFilter: null,
            partnerFilter: null,
            distributorFilter: null
        },
        domainItems: [],
        tasksSubscription: null,
        productTypes: [],
        updatedTasks: 1,
        updatedVendorRiskManagement: 2,
        updatedSecurityBenchmarks: 3,
        updatedSharedScans: 4,
        updatedDealRegs: 5,
        tasksOfDomain: [],
        organization: null,
        organizations: [],
        organizationsLimited: [],
        roles: [],
        users: [],
        configs: [],
        categoryIds: [],
        riskListTasks: [],
        vendorRiskManagement: [],
        riskComparision: [],
        mailContact: '',
        organizationStyle: null,
        standardPricing: {},
        showDefaultSnackbar: false,
        defaultSnackbarText: '',
        fpConfig: {
            configProcessing: false,
            reportConfig: [],
            serverConfigChanges: []
        },
        tags: {
            configTags: [],
            hostTags: undefined,
            tagFilter: [],
            tagOverlayApplied: false,
            tagGroup: undefined
        },
        scanConfig: {},
        canModifyItemConfig: false,
        tours: {
            singleTourSteps: {},
            tourStepsToRemove: [],
            awaitTourTimeoutId: undefined,
            tours: {
                default: {tourName: 'default', steps: []}
            },
            tourBase: undefined,
            currentTour: undefined,
            currentStep: -1,
            clickTargets: {},
            tourAboutToStart: undefined,
            doNotShowAgain: {},
            allowShowAgain: {},
            stepsToObserve: [],
            stepsToUnobserve: {},
            isNavigating: false,
            mountedSteps: [],
            permanentHelpDots: [],
            permanentHelpVisible: false
        },
        scanData: {},
        questionnaires: [],
        questionnaire: {
            answerings: []
        },
        questionnaireAnsweringList: null,
        questionnaireListFilters: {
            nameFilter: null,
            ownerFilter: null
        },
        answeringListFilters: {},
        pendingAnswerings: {
            total: 0,
            self: 0,
            direct: null
        },
        multipleRowsExpansion: false,
        routeFlowHistory: [],
        displayLRAdminElements: false
    },
    mutations: {
        ...productMutations,
        ...taskMutations,
        ...authMutations,
        ...organizationMutations,
        ...userMutations,
        ...configMutations,
        ...sharedLinkMutations,
        ...errorMutations,
        ...pricingMutations,
        ...tourMutations,
        ...generalMutations,
        ...scanMutations,
        ...questionnaireMutations,
        ...routeFlowMutations
    },
    actions: {
        ...taskActions,
        ...productActions,
        ...dealRegActions,
        ...restActions,
        ...authActions,
        ...organizationActions,
        ...userActions,
        ...configActions,
        ...sharedLinkActions,
        ...pricingActions,
        ...ScanActions,
        ...CyberriskAnalysisActions,
        ...CyberriskComparisonActions,
        ...tourActions,
        ...questionnaireActions,
        ...routeFlowActions
    },
    getters: {
        ...displayGetters,
        ...enumGetters,
        ...authGetters,
        ...completeScanHTMLGetters,
        ...roleGetters,
        ...validationGetters,
        ...pricingGetters,
        ...productGetters,
        ...organizationGetters,
        ...configGetters,
        ...tourGetters,
        ...scanGetters,
        ...questionnaireGetters,
        ...generalGetters
    },
    modules: {}
})
