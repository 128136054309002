import Vue from 'vue'

export default {
    setRoles(state, roles) {
        Vue.set(state, 'roles', roles)
    },

    setUsers(state, users) {
        Vue.set(state, 'users', users)
    },

    setUserGrants(state, userGrants) {
        Vue.set(state, 'userGrants', userGrants)
    },

    setOwnGrants(state, userGrants) {
        Vue.set(state, 'ownGrants', userGrants)
    }
}
