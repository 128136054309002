const catHelper = require('./categories')

module.exports = {
    getDescription: getDescription,
    getRisks: getRisks,
    getRemedy: getRemedy,
    checkIfItemHasText: checkIfItemHasText,
    getConfigField: getConfigField
}
function makeUnique(arr) {
    return arr.filter(function (item, i, ar) {
        return ar.indexOf(item) === i
    })
}

function getConfigField(item, ip, config, field) {
    let matchedValues = []
    if (config?.rating?.filterItems[item.parentId]) {
        let identifier = 'all'
        if (config.rating.filterItems[item.parentId][identifier] && config.rating.filterItems[item.parentId][identifier][field]) {
            matchedValues.push(config.rating.filterItems[item.parentId][identifier][field])
        }
    }
    if (config?.rating?.filterItems[item.uuid]) {
        let identifier = 'all'
        if (config.rating.filterItems[item.uuid][identifier] && config.rating.filterItems[item.uuid][identifier][field]) {
            matchedValues.push(config.rating.filterItems[item.uuid][identifier][field])
        }

        if (ip) {
            identifier = ip
            if (config.rating.filterItems[item.uuid][identifier] && config.rating.filterItems[item.uuid][identifier][field]) {
                matchedValues.push(config.rating.filterItems[item.uuid][identifier][field])
            }
        } else {
            item.ip.forEach((ip) => {
                identifier = ip
                if (config.rating.filterItems[item.uuid][identifier] && config.rating.filterItems[item.uuid][identifier][field]) {
                    matchedValues.push(config.rating.filterItems[item.uuid][identifier][field])
                }
            })
        }

        item.location.forEach((location) => {
            identifier = location
            if (config.rating.filterItems[item.uuid][identifier] && config.rating.filterItems[item.uuid][identifier][field]) {
                matchedValues.push(config.rating.filterItems[item.uuid][identifier][field])
            }
        })
    }
    matchedValues = makeUnique(matchedValues)
    return matchedValues
}

function checkIfItemHasText(item, t) {
    if (item.type === catHelper.category.dsgvo) {
        return false
    }
    const desc = this.getDescription(item.type, item.id, item.title)
    const risk = this.getRisks(item.type, item.id, item.title)
    const reme = this.getRemedy(item.type, item.id, item.title)
    const transDesc = t(desc)
    const transRisk = t(risk)
    const transReme = t(reme)
    // Check if description/risks/remedy are not empty
    // And translation is not the same like the input
    const resDesc = desc !== '' && desc !== transDesc
    const resRisk = risk !== '' && risk !== transRisk
    const resReme = reme !== '' && reme !== transReme

    return resDesc && resRisk && resReme
}

function getDescription(type, id, title) {
    switch (type) {
        case catHelper.category.app:
            return 'appTitles.description'
        case catHelper.category.app_nested:
            return 'appTitles.description'
        case catHelper.category.infection:
            return 'infectionTitles.description'
        case catHelper.category.network:
            return 'networkTitles.description'
        case catHelper.category.dns:
            return getDescriptionFromDNS(id)
        case catHelper.category.ssl:
            return getDescriptionFromSSL(id, title)
        case catHelper.category.ssl_nested:
            return getDescriptionFromSSL(id, title)
        case catHelper.category.web:
            return getDescriptionFromWeb(id)
        case catHelper.category.breach:
            return 'breachTitles.description'
        case catHelper.category.dsgvo_nested:
            return getDescriptionFromDSGVO(id, title)
        case catHelper.category.dsgvo:
            return getDescriptionFromDSGVO(id, title)
        case catHelper.category.ddos:
            return getTextFromDdos(id,title,"description")
        case catHelper.category.ddos_nested:
            return getTextFromDdos(id,title,"description")
        default:
            return ''
    }
}

function getDescriptionFromWeb(id) {
    switch (id) {
        case catHelper.itemId.web.git:
            return 'webTitles.gitTitles.description'
        case catHelper.itemId.web.svn:
            return 'webTitles.svnTitles.description'
        case 'insecure_redirect':
            return 'webTitles.redirectTitles.description'
        case catHelper.itemId.web.certificates:
            return 'webTitles.configTitles.description'
        case catHelper.itemId.web.missingHeaders:
            return 'webTitles.headerTitles.description'
        default:
            return 'webTitles.' + id.split('-').join('') + '.description'
    }
}



function mapDDosIdToLanguageKey(id,title) {

    console.log(id,title)
    switch (id) {


        case catHelper.itemId.ddos.multipleARecords:
            return 'ddos_titles.multiple_a_records'

        case catHelper.itemId.ddos.cdn:
            return 'ddos_titles.cdn'
        case catHelper.itemId.ddos.nsEntries:
            return 'ddos_titles.ns_entries'
        case catHelper.itemId.ddos.mxEntries:
            return 'ddos_titles.mx_entries'
        case catHelper.itemId.ddos.multipleNsIp:
            return 'ddos_titles.multiple_ns_ip'
        case catHelper.itemId.ddos.multipleNsLocation:
            return 'ddos_titles.multiple_ns_location'
        case catHelper.itemId.ddos.multipleNsHoster:
            return 'ddos_titles.multiple_ns_hoster'
        case catHelper.itemId.ddos.multipleMxIp:
            return 'ddos_titles.multiple_mx_ip'
        case catHelper.itemId.ddos.multipleMxLocation:
            return 'ddos_titles.multiple_mx_location'
        case catHelper.itemId.ddos.multipleMxHoster:
            return 'ddos_titles.multiple_mx_hoster'
        case catHelper.itemId.ddos.ttfb:
            return 'ddos_titles.ttfb'
        case catHelper.itemId.ddos.webSize:
            return 'ddos_titles.web_size'
        default:
            return ''
    }
}

function getTextFromDdos(id,title,field) {
    return mapDDosIdToLanguageKey(id,title) + '.'+field
}

function getDescriptionFromDSGVO(id, title) {
    switch (id) {
        case catHelper.itemId.dsgvo_nested.googleResource:
            return 'dsgvoTitles.google_resource.description'
        case catHelper.itemId.dsgvo_nested.serverLocation:
            return 'dsgvoTitles.server_location.description'
        default:
            if (title == catHelper.itemTitles.dsgvo_nested.script) return 'dsgvoTitles.third_party_resource.description'
            else if (title == catHelper.itemTitles.dsgvo_nested.cookie) return 'dsgvoTitles.description'
            return ''
    }
}

function getDescriptionFromSSL(id, title) {
    switch (id) {
        case 'Fehlkonfiguration':
            return 'sslTitles.misconfiguration.description'
        case 'HEARTBLEED':
            return 'sslTitles.heartbleed.description'
        case 'CCS':
            return 'sslTitles.css.description'
        case 'TICKETBLEED':
            return 'sslTitles.ticketbleed.description'
        case 'ROBOT':
            return 'sslTitles.robot.description'
        case 'CRIME':
            return 'sslTitles.crime.description'
        case 'BREACH':
            return 'sslTitles.breach.description'
        case 'POODLE_SSL':
            return 'sslTitles.poodleSSL.description'
        case 'SWEET32':
            return 'sslTitles.sweet32.description'
        case 'BEAST_CBC_TLS1':
            return 'sslTitles.beast.description'
        case 'BEAST_CBC_SSL3':
            return 'sslTitles.beast.description'
        case 'BEAST':
            return 'sslTitles.beast.description'
        case 'LUCKY13':
            return 'sslTitles.lucky13.description'
        case 'FREAK':
            return 'sslTitles.freak.description'
        case 'LOGJAM':
            return 'sslTitles.logjam.description'
        case 'LOGJAM-common_primes':
            return 'sslTitles.logjam.description'
        case 'DROWN':
            return 'sslTitles.drown.description'
        case 'RC4':
            return 'sslTitles.rc4.description'
        case catHelper.itemId.ssl_nested.aggregated:
            return 'sslTitles.aggregatedTitles.description'
        case catHelper.itemId.ssl_nested.ciphers:
            return 'sslTitles.cipherTitles.description'
        case catHelper.itemId.ssl_nested.expiry:
            return 'sslTitles.expiryTitles.description'
        case catHelper.itemId.ssl_nested.invalid:
            return 'sslTitles.invalidTitles.description'
        default:
            if (title) {
                return title.replace('ssl_titles', 'sslTitles') + '.description'
            }
            return ''
    }
}

function getDescriptionFromDNS(id) {
    switch (id) {
        case 'spf':
            return 'dnsTitles.spfTitles.description'
        case 'spfSub':
            return 'dnsTitles.spfTitles.description'
        case 'validDmarc':
            return 'dnsTitles.dmarcTitles.description'
        case 'dmarc':
            return 'dnsTitles.dmarcTitles.description'
        case 'daneHttps':
            return 'dnsTitles.daneTitles.description'
        case 'daneSmtp':
            return 'dnsTitles.daneTitles.description'
        case 'caa':
            return 'dnsTitles.caa.description'
        case 'tlsahttps':
            return 'dnsTitles.tlsahttps.description'
        case 'soa':
            return 'dnsTitles.soa.description'
        case 'zonetransfer':
            return 'dnsTitles.zonetransfer.description'
        case 'dnssec':
            return 'dnsTitles.dnssec.description'
        case 'openresolvers':
            return 'dnsTitles.openresolvers.description'
        case 'whois':
            return 'dnsTitles.whois.description'
        case 'mx':
            return 'dnsTitles.mx.description'
        default:
            return 'dns_titles.' + id + '.description'
    }
}

function getRisks(type, id, title) {
    switch (type) {
        case catHelper.category.app:
            return 'appTitles.risks'
        case catHelper.category.app_nested:
            return 'appTitles.risks'
        case catHelper.category.infection:
            return 'infectionTitles.risks'
        case catHelper.category.network:
            return getRisksFromNetwork(id)
        case catHelper.category.dns:
            return getRisksFromDNS(id)
        case catHelper.category.web:
            return getRisksFromWeb(id)
        case catHelper.category.ssl:
            return getRisksFromSSL(id, title)
        case catHelper.category.ssl_nested:
            return getRisksFromSSL(id, title)
        case catHelper.category.breach:
            return 'breachTitles.risks'
        case catHelper.category.dsgvo_nested:
            return getRisksFromDSGVO(id, title)
        case catHelper.category.dsgvo:
            return getRisksFromDSGVO(id, title)
        case catHelper.category.ddos:
            return getTextFromDdos(id,title,"risks")
        case catHelper.category.ddos_nested:
            return getTextFromDdos(id,title,"risks")
        default:
            return ''
    }
}

function getRisksFromDSGVO(id, title) {
    switch (id) {
        case catHelper.itemId.dsgvo_nested.googleResource:
            return 'dsgvoTitles.google_resource.risks'
        case catHelper.itemId.dsgvo_nested.serverLocation:
            return 'dsgvoTitles.server_location.risks'
        default:
            if (title == catHelper.itemTitles.dsgvo_nested.script) return 'dsgvoTitles.third_party_resource.risks'
            else if (title == catHelper.itemTitles.dsgvo_nested.cookie) return 'dsgvoTitles.risks'
            return ''
    }
}

function getRisksFromNetwork(id) {
    switch (id) {
        case catHelper.itemId.network.rpc:
            return 'networkTitles.rpcTitles.risks'
        default:
            return 'networkTitles.risks'
    }
}

function getRisksFromWeb(id) {
    switch (id) {
        case catHelper.itemId.web.git:
            return 'webTitles.gitTitles.risks'
        case catHelper.itemId.web.svn:
            return 'webTitles.svnTitles.risks'
        case 'insecure_redirect':
            return 'webTitles.redirectTitles.risks'
        case catHelper.itemId.web.certificates:
            return 'webTitles.configTitles.risks'
        case catHelper.itemId.web.missingHeaders:
            return 'webTitles.headerTitles.risks'
        default:
            return 'webTitles.' + id.split('-').join('') + '.risks'
    }
}

function getRisksFromSSL(id, title) {
    switch (id) {
        case 'Fehlkonfiguration':
            return 'sslTitles.misconfiguration.risks'
        case 'HEARTBLEED':
            return 'sslTitles.heartbleed.risks'
        case 'CCS':
            return 'sslTitles.css.risks'
        case 'TICKETBLEED':
            return 'sslTitles.ticketbleed.risks'
        case 'ROBOT':
            return 'sslTitles.robot.risks'
        case 'CRIME':
            return 'sslTitles.crime.risks'
        case 'BREACH':
            return 'sslTitles.breach.risks'
        case 'POODLE_SSL':
            return 'sslTitles.poodleSSL.risks'
        case 'SWEET32':
            return 'sslTitles.sweet32.risks'
        case 'BEAST_CBC_TLS1':
            return 'sslTitles.beast.risks'
        case 'BEAST_CBC_SSL3':
            return 'sslTitles.beast.risks'
        case 'BEAST':
            return 'sslTitles.beast.risks'
        case 'LUCKY13':
            return 'sslTitles.lucky13.risks'
        case 'FREAK':
            return 'sslTitles.freak.risks'
        case 'LOGJAM':
            return 'sslTitles.logjam.risks'
        case 'LOGJAM-common_primes':
            return 'sslTitles.logjam.risks'
        case 'DROWN':
            return 'sslTitles.drown.risks'
        case 'RC4':
            return 'sslTitles.rc4.risks'
        case catHelper.itemId.ssl_nested.aggregated:
            return 'sslTitles.aggregatedTitles.risks'
        case catHelper.itemId.ssl_nested.ciphers:
            return 'sslTitles.cipherTitles.risks'
        case catHelper.itemId.ssl_nested.expiry:
            return 'sslTitles.expiryTitles.risks'
        case catHelper.itemId.ssl_nested.invalid:
            return 'sslTitles.invalidTitles.risks'
        default:
            if (title) {
                return title.replace('ssl_titles', 'sslTitles') + '.risks'
            }
            return ''
    }
}

function getRisksFromDNS(id) {
    switch (id) {
        case 'spf':
            return 'dnsTitles.spfTitles.risks'
        case 'spfSub':
            return 'dnsTitles.spfTitles.risks'
        case 'validDmarc':
            return 'dnsTitles.dmarcTitles.risks'
        case 'dmarc':
            return 'dnsTitles.dmarcTitles.risks'
        case 'daneHttps':
            return 'dnsTitles.daneTitles.risks'
        case 'daneSmtp':
            return 'dnsTitles.daneTitles.risks'
        case 'caa':
            return 'dnsTitles.caa.risks'
        case 'tlsahttps':
            return 'dnsTitles.tlsahttps.risks'
        case 'soa':
            return 'dnsTitles.soa.risks'
        case 'zonetransfer':
            return 'dnsTitles.zonetransfer.risks'
        case 'dnssec':
            return 'dnsTitles.dnssec.risks'
        case 'openresolvers':
            return 'dnsTitles.openresolvers.risks'
        case 'whois':
            return 'dnsTitles.whois.risks'
        case 'mx':
            return 'dnsTitles.mx.risks'
        default:
            return 'dns_titles.' + id + '.risks'
    }
}

function getRemedy(type, id, title) {
    switch (type) {
        case catHelper.category.app:
            return 'appTitles.remedy'
        case catHelper.category.app_nested:
            return 'appTitles.remedy'
        case catHelper.category.infection:
            return 'infectionTitles.remedy'
        case catHelper.category.network:
            return 'networkTitles.remedy'
        case catHelper.category.dns:
            return getRemedyFromDNS(id)
        case catHelper.category.ssl:
            return getRemedyFromSSL(id, title)
        case catHelper.category.ssl_nested:
            return getRemedyFromSSL(id, title)
        case catHelper.category.web:
            return getRemedyFromWeb(id)
        case catHelper.category.breach:
            return 'breachTitles.remedy'
        case catHelper.category.dsgvo_nested:
            return getRemedyFromDSGVO(id, title)
        case catHelper.category.dsgvo:
            return getRemedyFromDSGVO(id, title)
        case catHelper.category.ddos:
            return getTextFromDdos(id,title,"remedy")
        case catHelper.category.ddos_nested:
            return getTextFromDdos(id,title,"remedy")
        default:
            return ''
    }
}

function getRemedyFromDSGVO(id, title) {
    switch (id) {
        case catHelper.itemId.dsgvo_nested.googleResource:
            return 'dsgvoTitles.google_resource.remedy'
        case catHelper.itemId.dsgvo_nested.serverLocation:
            return 'dsgvoTitles.server_location.remedy'
        default:
            if (title == catHelper.itemTitles.dsgvo_nested.script) return 'dsgvoTitles.third_party_resource.remedy'
            else if (title == catHelper.itemTitles.dsgvo_nested.cookie) return 'dsgvoTitles.remedy'
            return ''
    }
}

function getRemedyFromWeb(id) {
    switch (id) {
        case catHelper.itemId.web.git:
            return 'webTitles.gitTitles.remedy'
        case catHelper.itemId.web.svn:
            return 'webTitles.svnTitles.remedy'
        case 'insecure_redirect':
            return 'webTitles.redirectTitles.remedy'
        case catHelper.itemId.web.certificates:
            return 'webTitles.configTitles.remedy'
        case catHelper.itemId.web.missingHeaders:
            return 'webTitles.headerTitles.remedy'
        default:
            return 'webTitles.' + id.split('-').join('') + '.remedy'
    }
}

function getRemedyFromSSL(id, title) {
    switch (id) {
        case 'Fehlkonfiguration':
            return 'sslTitles.misconfiguration.remedy'
        case 'HEARTBLEED':
            return 'sslTitles.heartbleed.remedy'
        case 'CCS':
            return 'sslTitles.css.remedy'
        case 'TICKETBLEED':
            return 'sslTitles.ticketbleed.remedy'
        case 'ROBOT':
            return 'sslTitles.robot.remedy'
        case 'CRIME':
            return 'sslTitles.crime.remedy'
        case 'BREACH':
            return 'sslTitles.breach.remedy'
        case 'POODLE_SSL':
            return 'sslTitles.poodleSSL.remedy'
        case 'SWEET32':
            return 'sslTitles.sweet32.remedy'
        case 'BEAST_CBC_TLS1':
            return 'sslTitles.beast.remedy'
        case 'BEAST_CBC_SSL3':
            return 'sslTitles.beast.remedy'
        case 'BEAST':
            return 'sslTitles.beast.remedy'
        case 'LUCKY13':
            return 'sslTitles.lucky13.remedy'
        case 'FREAK':
            return 'sslTitles.freak.remedy'
        case 'LOGJAM':
            return 'sslTitles.logjam.remedy'
        case 'LOGJAM-common_primes':
            return 'sslTitles.logjam.remedy'
        case 'DROWN':
            return 'sslTitles.drown.remedy'
        case 'RC4':
            return 'sslTitles.rc4.remedy'
        case catHelper.itemId.ssl_nested.aggregated:
            return 'sslTitles.aggregatedTitles.remedy'
        case catHelper.itemId.ssl_nested.ciphers:
            return 'sslTitles.cipherTitles.remedy'
        case catHelper.itemId.ssl_nested.expiry:
            return 'sslTitles.expiryTitles.remedy'
        case catHelper.itemId.ssl_nested.invalid:
            return 'sslTitles.invalidTitles.remedy'
        default:
            if (title) {
                return title.replace('ssl_titles', 'sslTitles') + '.remedy'
            }
            return ''
    }
}

function getRemedyFromDNS(id) {
    switch (id) {
        case 'spf':
            return 'dnsTitles.spfTitles.remedy'
        case 'spfSub':
            return 'dnsTitles.spfTitles.remedy'
        case 'validDmarc':
            return 'dnsTitles.dmarcTitles.remedy'
        case 'dmarc':
            return 'dnsTitles.dmarcTitles.remedy'
        case 'daneHttps':
            return 'dnsTitles.daneTitles.remedy'
        case 'daneSmtp':
            return 'dnsTitles.daneTitles.remedy'
        case 'caa':
            return 'dnsTitles.caa.remedy'
        case 'tlsahttps':
            return 'dnsTitles.tlsahttps.remedy'
        case 'soa':
            return 'dnsTitles.soa.remedy'
        case 'zonetransfer':
            return 'dnsTitles.zonetransfer.remedy'
        case 'dnssec':
            return 'dnsTitles.dnssec.remedy'
        case 'openresolvers':
            return 'dnsTitles.openresolvers.remedy'
        case 'whois':
            return 'dnsTitles.whois.remedy'
        case 'mx':
            return 'dnsTitles.mx.remedy'
        default:
            return 'dns_titles.' + id + '.remedy'
    }
}
