export default {
    async loadDealRegs({state, dispatch, commit}) {
        const response = await fetch(`${Window.$apiUrl}/api/v1/rest/deals/all`, {
            method: 'GET',
            credentials: 'include'
        })
        /*
    const count = await fetch(
      `${Window.$apiUrl}/api/rest/product/all/count`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({})
      })

    const response = await Promise.all([tasks, count])
    const responseAll = await Promise.all([response[0].json(), response[1].text()])
    */
        const dealRegs = await response.json()
        return dealRegs
    }
}
