module.exports = {
    ONETIME: 0,
    DEACTIVATED: -1,
    DAILY: 24,
    WEEKLY: 168,
    BIWEEKLY: 336,
    MONTHLY: 720,
    QUARTERLY: 2160, //2190
    HALF_YEARLY: 4320, //4380
    YEARLY: 8760
}
// wrong values but doesn't interest.. because we convert from hours to month with switch case...
