const industryTypes = {
    AGRICULTURE_FORESTRY_FISHING: 'Agriculture, Forestry, and Fishing',
    CONSTRUCTION: 'Construction',
    FINANCE_INSURANCE_REAL_ESTATE: 'Finance, Insurance, and Real Estate',
    MANUFACTURING: 'Manufacturing',
    MINING: 'Mining',
    PUBLIC_SECTOR: 'Public Sector',
    RETAIL_TRADE: 'Retail Trade',
    SERVICES: 'Services',
    TRANSPORTATION_COMMUNICATIONS_ELECRIC_GAS_SANITARY_SERVICE: 'Transportation, Communications, Electric, Gas, and Sanitary Services',
    UNKNOWN_INDUSTRY: 'Unknown Industry',
    WHOLESALE_TRADE: 'Wholesale Trade'
}

module.exports = industryTypes
