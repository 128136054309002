import validator from 'validator'

export default {
    isJSON: (state) => (jsonString) => {
        try {
            const o = JSON.parse(jsonString)

            // Handle non-exception-throwing cases:
            // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
            // but... JSON.parse(null) returns null, and typeof null === "object",
            // so we must check for that, too. Thankfully, null is falsey, so this suffices:
            if (o && typeof o === 'object') {
                return true
            }
        } catch (e) {
            console.log(e)
        }

        return false
    },

    isURL: () => (url) => {
        try {
            return validator.isURL(url)
        } catch {
            return false
        }
    },

    isEmail: () => (email) => {
        try {
            return validator.isEmail(email)
        } catch {
            return false
        }
    }
}
