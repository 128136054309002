export default {
    async getCyberriskComparisons({dispatch}, {page = undefined, perPage = undefined, includes, sort, filter}) {
        const params = new URLSearchParams()

        if (includes) {
            params.append('includes', includes.join(','))
        }
        if (page > 0) {
            params.append('page', page)
        }
        if (perPage > 0) {
            params.append('per_page', perPage)
        }
        if (Array.isArray(sort) && sort.length > 0) {
            params.append('sort', sort.flat().join(','))
        }
        if (Array.isArray(filter) && filter.length > 0) {
            for (const item of filter) {
                params.append(`filter_${item[0]}`, item[1])
            }
        }

        return fetch(`${Window.$apiUrl}/api/v1/rest/products/cyberrisk-comparison/?${params.toString()}`, {
            method: 'GET',
            credentials: 'include'
        })
    }
}
