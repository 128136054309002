import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import de from 'vuetify/es5/locale/de'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        dark: false,
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#00051D',
                secondary: '#363636',
                accent: '#35bee6',
                error: '#ff0e61',
                info: '#2196F3',
                success: '#36ddcf',
                warning: '#f6bf28',
                background: '#F4F6FB'
            },
            dark: {
                background: '#121212'
            }
        }
    },
    lang: {
        locales: {de, en},
        current: 'de'
    }
})
