export default {
    async loadProducts({state, dispatch, commit}, onlyIds = false) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/product/all?onlyIds=${onlyIds}`, {
            method: 'GET',
            credentials: 'include'
        })
        /*
    const count = await fetch(
      `${Window.$apiUrl}/api/rest/product/all/count`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({})
      })

    const response = await Promise.all([tasks, count])
    const responseAll = await Promise.all([response[0].json(), response[1].text()])
    */
        const tasks = await response.json()
        return tasks
    },

    async loadTasksOfRiskList({state, dispatch, commit}, id) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/product/risk-list/${id}`, {
            method: 'POST',
            credentials: 'include'
        })

        const json = await response.json()
        commit('setTasksOfRiskList', json)
    },

    async sendOrderMail({state, dispatch, commit}, data) {
        const response = await fetch(`${Window.$apiUrl}/api/v1/rest/products/send-order-mail/`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data)
        })

        return response
    },

    async loadProductTypes({state, dispatch, commit}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/product/types`, {
            method: 'POST',
            credentials: 'include'
        })
        if (response.ok) {
            const json = await response.json()
            commit('setProductTypes', json)
        }
    },

    async loadRiskManagement({state, dispatch, commit}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/product/risk-management`, {
            method: 'POST',
            credentials: 'include'
        })
        if (response.ok) {
            const json = await response.json()
            return json
            // commit('setRiskManagementLists', json)
        }
    },

    async updateRiskManagement({state, dispatch, commit}, data) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/product/risk-management-update`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data)
        })
        if (response.ok) {
            const json = await response.json()
            return json
        } else {
            return false
        }
    },

    async createRiskManagement({state, dispatch}, {name}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/risk-management/create`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                name: name
            })
        })
        if (response.ok) {
            return await response.json()
        }
        return false
    },

    async loadRiskComparison({state, dispatch, commit}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/product/risk-comparison`, {
            method: 'POST',
            credentials: 'include'
        })
        if (response.ok) {
            const json = await response.json()
            commit('setRiskComparisionLists', json)
        }
    },

    async createRiskComparision({state, dispatch}, {name}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/risk-comparison/create`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                name: name
            })
        })
        if (response.ok) {
            return (await response.json()).response
        }
        return false
    },

    async downloadRiskComparision({state, dispatch}, {productId, taskId}) {
        let response = await fetch(`${Window.$apiUrl}/api/rest/risk-comparison/${productId}/download-pdf`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({})
        })
        if (response.ok) {
            response = await response
            return response.body
        }
    },

    async editRiskList({state, dispatch}, {name, id}) {
        await fetch(`${Window.$apiUrl}/api/rest/risk-management/${id}/edit`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                name: name
            })
        })
    },

    async importTaskToRiskComparison({state, dispatch}, params) {
        if (params.comparisonId && params.taskId) {
            await fetch(`${Window.$apiUrl}/api/rest/risk-comparison/${params.comparisonId}/task/${params.taskId}/import`, {
                method: 'POST',
                credentials: 'include'
            })
        }
    }
}
