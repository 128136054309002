import dayjs from 'dayjs'

export default {

  formatTimestamp: () => (value, format = 'DD.MM.YYYY HH:mm') => {
    return dayjs(value).format(format)
  },

  formatNumber: () => (value) => {
    if (value) {
      return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    } else {
      return '-'
    }
  }

}
