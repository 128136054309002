<template>
    <v-navigation-drawer temporary app :value="drawer" @input="(ev) => $emit('close', ev)">
        <v-list dense>
            <v-list-item link to="/">
                <v-list-item-icon>
                    <v-icon>mdi-view-dashboard</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-weight-bold">
                    {{ $t('navigation.products') }}
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/my-profile">
                <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-weight-bold">
                    {{ $t('navigation.my_profile') }}
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/users">
                <v-list-item-icon>
                    <v-icon>mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-weight-bold">
                    {{ $t('navigation.users') }}
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/organizations">
                <v-list-item-icon>
                    <v-icon>mdi-list-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-weight-bold">
                    {{ $t('navigation.companies') }}
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/questionnaires" id="questionnaireLink">
                <v-list-item-icon id="questionnaireIcon">
                    <v-icon>mdi-order-bool-ascending-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-weight-bold">
                    <v-badge
                        id="questionnaireBadge"
                        color="red"
                        :content="answeringCount"
                        overlap
                        :offset-x="xOffset"
                        offset-y="12"
                        :value="answeringCount > 0"
                    >
                        <span ref="questionnaire">
                            {{ $t('navigation.questionnaires') }}
                        </span>
                    </v-badge>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/configs" class="lr-admin" v-if="isLocateRiskAdmin && displayLRAdminElements">
                <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-weight-bold">
                    {{ $t('navigation.configs') }}
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/pricing" v-if="canChangePricing">
                <v-list-item-icon>
                    <v-icon>mdi-cash-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-weight-bold">
                    {{ $t('navigation.pricing') }}
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/bookings" class="lr-admin" v-if="isLocateRiskAdmin && displayLRAdminElements">
                <v-list-item-icon>
                    <v-icon>mdi-home-analytics</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-weight-bold">
                    {{ $t('navigation.bookings') }}
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/admin" class="lr-admin" v-if="isLocateRiskAdmin && displayLRAdminElements">
                <v-list-item-icon>
                    <v-icon>mdi-cupcake</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-weight-bold">
                    {{ $t('navigation.admin') }}
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/release-notes">
                <v-list-item-icon>
                    <v-icon>mdi-note</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-weight-bold">
                    {{ $t('navigation.release_notes') }}
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/edit-release-notes" class="lr-admin" v-if="isLocateRiskAdmin && displayLRAdminElements">
                <v-list-item-icon>
                    <v-icon>mdi-note</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-weight-bold">
                    {{ $t('navigation.edit_release_notes') }}
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
import QuestionnaireService from '@/services/QuestionnaireService.js'

export default {
    name: 'Navigation',
    props: {
        drawer: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            fetchAnsweringCountInterval: null,
            xOffset: 12,
            onLanguageChange: () => setTimeout(this.repositionBadge, 300)
        }
    },
    async created() {
        await this.fetchAnsweringCount()
        // this.fetchAnsweringCountInterval = setInterval(this.fetchAnsweringCount, 60000)
        this.$i18n.i18next.on('languageChanged', this.onLanguageChange)
    },
    beforeDestroy() {
        this.$i18n.i18next.off('languageChanged', this.onLanguageChange)
        // clearInterval(this.fetchAnsweringCountInterval)
    },
    computed: {
        ...mapGetters([
            'currentUser',
            'currentUserOrganization',
            'canChangePricing',
            'isLocateRiskAdmin',
            'isPartner',
            'pendingAnswerings',
            'displayLRAdminElements',
            'questionnaireAnsweringList'
        ]),
        answeringCount() {
            return this.pendingAnswerings?.total ?? 0
        }
    },
    methods: {
        ...mapMutations(['setPendingAnswerings']),
        ...mapActions(['loadQuestionnairesAndAnswerings']),
        repositionBadge() {
            setTimeout(() => {
                const linkElement = document.getElementById('questionnaireLink')
                const iconElement = document.getElementById('questionnaireIcon')
                const textElement = this.$refs['questionnaire']
                if (linkElement?.getBoundingClientRect() && iconElement?.getBoundingClientRect() && textElement?.getBoundingClientRect()) {
                    const xOffset = Math.floor(
                        linkElement.getBoundingClientRect().width - (textElement.getBoundingClientRect().width + iconElement.getBoundingClientRect().width + 64)
                    )
                    this.xOffset = xOffset
                }
            }, 200)
        },
        async fetchAnsweringCount() {
            let response = this.questionnaireAnsweringList
            if (!response) {
                await this.loadQuestionnairesAndAnswerings()
                response = this.questionnaireAnsweringList
            }
            if (response) {
                const totalAnswerings = response.answerings.filter((x) => !x.locked && x.active)
                const selfAnswerings = totalAnswerings.filter((x) => x.OwnedBy === this.currentUser.companyId)
                const directAnswering = selfAnswerings.length == 1 ? selfAnswerings[0].id : undefined
                this.setPendingAnswerings({total: totalAnswerings.length, self: selfAnswerings.length, direct: directAnswering})
                this.repositionBadge()
            }
        }
    }
}
</script>

<style scoped></style>
