export default {

  showDefaultError (state) {
    state.showDefaultError = true
  },

  hideDefaultError (state) {
    state.showDefaultError = false
  }

}
