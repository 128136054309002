// text will be translated
const questionTypeValues = {
    TEXT: {text: 'Text', value: 0, icon: 'mdi-text', t: 'questionnaires.question.typeValues.text', e: 'questionnaires.question.typeHints.text'},
    SINGLE: {
        text: 'Single choice',
        value: 1,
        icon: 'mdi-order-bool-descending',
        t: 'questionnaires.question.typeValues.singleChoice',
        e: 'questionnaires.question.typeHints.singleChoice'
    },
    MULTIPLE: {
        text: 'Multiple choice',
        value: 2,
        icon: 'mdi-order-bool-descending-variant',
        t: 'questionnaires.question.typeValues.multipleChoice',
        e: 'questionnaires.question.typeHints.multipleChoice'
    },
    EVIDENCE: {
        text: 'Only evidence',
        value: 3,
        icon: 'mdi-file-document',
        t: 'questionnaires.question.typeValues.onlyEvidence',
        e: 'questionnaires.question.typeHints.onlyEvidence'
    },
    PERCENTAGE: {
        text: 'Percentage',
        value: 4,
        icon: 'mdi-tune-variant',
        t: 'questionnaires.question.typeValues.percentage',
        e: 'questionnaires.question.typeHints.percentage'
    },
    TEXTSCORED: {
        text: 'Text (Scored)',
        value: 5,
        icon: 'mdi-text-search',
        t: 'questionnaires.question.typeValues.textscored',
        e: 'questionnaires.question.typeHints.textscored'
    }
} // IDEA: instead of percentage type add templates for multiple choice to add 10%, 20%, 30%, questions

// text will be translated
const questionEvidenceValues = {
    NONE: {
        text: 'None',
        value: 0,
        icon: 'mdi-file-document-remove',
        t: 'questionnaires.question.evidenceValues.none',
        e: 'questionnaires.question.evidenceHints.none'
    },
    OPTIONAL: {
        text: 'Optional',
        value: 1,
        icon: 'mdi-file-document',
        t: 'questionnaires.question.evidenceValues.optional',
        e: 'questionnaires.question.evidenceHints.optional'
    },
    MANDATORY: {
        text: 'Mandatory',
        value: 2,
        icon: 'mdi-file-document-alert',
        t: 'questionnaires.question.evidenceValues.mandatory',
        e: 'questionnaires.question.evidenceHints.mandatory'
    }
}

const questionVariantValues = {
    NORMAL: {
        text: 'Normal',
        value: 0,
        icon: 'mdi-help-circle-outline',
        t: 'questionnaires.question.variantValues.normal',
        e: 'questionnaires.question.variantExplanations.normal'
    },
    LIST: {
        text: 'List',
        value: 1,
        icon: 'mdi-format-list-numbered',
        t: 'questionnaires.question.variantValues.list',
        e: 'questionnaires.question.variantExplanations.list'
    }
}

const questionConditionValues = {
    EXACT: {
        text: 'Exact',
        value: 0,
        icon: 'mdi-equal',
        t: 'questionnaires.question.conditionValues.exact',
        e: 'questionnaires.question.conditionExplanations.exact'
    },
    ANY: {
        text: 'Any',
        value: 1,
        icon: 'mdi-approximately-equal',
        t: 'questionnaires.question.conditionValues.any',
        e: 'questionnaires.question.conditionExplanations.any'
    },
    NONE: {
        text: 'None',
        value: 2,
        icon: 'mdi-not-equal-variant',
        t: 'questionnaires.question.conditionValues.none',
        e: 'questionnaires.question.conditionExplanations.none'
    }
}

const questionConditionJoinValues = {
    ANY: {
        text: 'Any',
        value: 0,
        icon: 'mdi-gate-or',
        t: 'questionnaires.question.conditionJoinValues.any',
    },
    ALL: {
        text: 'All',
        value: 1,
        icon: 'mdi-gate-and-equal',
        t: 'questionnaires.question.conditionJoinValues.all'
    }
}

const questionLinkSelectionLevels = {
    QUESTIONNAIRE_LEVEL: {text: 'Questionnaire level', value: 0},
    CATEGORY_LEVEL: {text: 'Category level', value: 1},
    QUESTION_LEVEL: {text: 'Question level', value: 2}
}

// all values have to be === index in Object.keys or Object.values

exports.questionTypeValues = questionTypeValues
exports.questionEvidenceValues = questionEvidenceValues
exports.questionVariantValues = questionVariantValues
exports.questionConditionValues = questionConditionValues
exports.questionConditionJoinValues = questionConditionJoinValues
exports.questionLinkSelectionLevels = questionLinkSelectionLevels
