const GRANT_TYPES = {
  EDIT_ROLES: 'EDIT_ROLES',
  READ_COMPANY: 'READ_COMPANY',
  CREATE_COMPANY: 'CREATE_COMPANY',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  DELETE_COMPANY: 'DELETE_COMPANY',
  PRIVILEGED_UPDATE_COMPANY: 'PRIVILEGED_UPDATE_COMPANY', // can make a comapny a partner
  READ_USER: 'READ_USER',
  CREATE_USER: 'CREATE_USER',
  UPDATE_USER: 'UPDATE_USER',
  PRIVILEGED_UPDATE_USER: 'PRIVILEGED_UPDATE_USER',
  DELETE_USER: 'DELETE_USER',
  BUY_PRODUCT: 'BUY_PRODUCT',
  SHARE_ITEM: 'SHARE_ITEM',
  READ_ITEM: 'READ_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  DELETE_ITEM: 'DELETE_ITEM',
  CREATE_ITEM: 'CREATE_ITEM',
  CHANGE_BRANDING: 'CHANGE_BRANDING',
  CHANGE_HIDDEN_CONFIGS: 'CHANGE_HIDDEN_CONFIGS',
  CHANGE_QUESTIONNAIRES: 'CHANGE_QUESTIONNAIRES',
  CHANGE_STANDARD_PRICING: 'CHANGE_STANDARD_PRICING',
  VIEW_BOOKINGS_HISTORY: 'VIEW_BOOKINGS_HISTORY',
  CALCULATE_SCAN_PRICE: 'CALCULATE_SCAN_PRICE' // can call the calculate-scan backend and therefore add a pricing to a scan
}

Object.freeze(GRANT_TYPES)
module.exports = GRANT_TYPES
