import Vue from 'vue'

export default {

  setTasksOfDomain (state, tasks) {
    Vue.set(state, 'tasksOfDomain', tasks);
  },

  setDomainItems (state, domains) {
    Vue.set(state, 'domainItems', domains);
  },

  setCategoryIds (state, categories) {
    Vue.set(state, 'categoryIds', categories);
  }

}
