/**
 * THis tour is not enabled, becasue it does not really provide any insights beyond the UI Texts.
 *
 */
export const VRMSupplierRegistrationTour = {
    tourName: 'VRMSupplierRegistrationTour',
    requiresElement: '.guided-tour-vrm-supplier-registration-step-1',
    steps: [
       /* {
            target: '.guided-tour-vrm-supplier-registration-step-1',
            content: 'new.translation.views.SupplierRegistration.inviteeRegistrationStepOne.content',
            padding: true
        },
        {
            target: '.guided-tour-vrm-supplier-registration-step-2',
            content: 'new.translation.views.SupplierRegistration.inviteeRegistrationStepTwo.content',
            padding: true
        },
        {
            target: '.guided-tour-vrm-supplier-registration-step-3',
            content: 'new.translation.views.SupplierRegistration.inviteeRegistrationStepThree.content',
            padding: true
        },
        {
            target: '.guided-tour-vrm-supplier-registration-step-4',
            content: 'new.translation.views.SupplierRegistration.inviteeRegistrationStepFour.content',
            padding: true
        },
        {
            target: '.guided-tour-vrm-supplier-registration-step-5',
            content: 'new.translation.views.SupplierRegistration.inviteeRegistrationStepFive.content',
            padding: true
        },
        {
            target: '.guided-tour-vrm-supplier-registration-step-6',
            content: 'new.translation.views.SupplierRegistration.inviteeRegistrationStepSix.content',
            padding: true
        }*/
    ]
}
