import '@mdi/font/css/materialdesignicons.css'
import '@fontsource/roboto'

import Vue from 'vue'

var apiUrl = process.env.VUE_APP_API_URL
import ENUMS from '../../models/models/enums'

Vue.prototype.$ENUMS = ENUMS
Vue.prototype.$apiUrl = apiUrl
Window.$apiUrl = apiUrl

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18next from './lang/i18next'
import VueI18Next from '@panter/vue-i18next'
import CountryFlag from 'vue-country-flag'
import VueCharts from 'vue-charts'
import VueMeta from 'vue-meta'
import VueExcelXlsx from 'vue-excel-xlsx'
import VueTour from './components/tours/main'
import dayjs from 'dayjs'
import 'dayjs/locale/de'

Vue.use(VueExcelXlsx)

Vue.use(VueMeta)

Vue.component('country-flag', CountryFlag)

Vue.use(VueI18Next)

const i18n = new VueI18Next(i18next)

i18n.i18next.on('languageChanged', function (lng) {
    const html = document.documentElement
    html.setAttribute('lang', lng)
    dayjs.locale(lng)
})

Vue.use(VueCharts)

require('./components/tours/assets/vue-tour.css')
Vue.use(VueTour)

import tourElementDirective from './components/tours/TourElementDirective'
Vue.directive('guided-tour', tourElementDirective)

import {ValidationProvider, configure, extend, ValidationObserver} from 'vee-validate'
import {required, between, email} from 'vee-validate/dist/rules'
import validator from 'validator'

import {regex} from 'vee-validate/dist/rules'
extend('regex', regex)

configure({
    defaultMessage: (field, values) => {
        //values._field_ = i18n.t(`fields.${field}`)
        return i18n.t(`validations.messages.${values._rule_}`, {values})
    }
})

extend('required', {
    ...required
})

extend('email', {
    ...email,
    message: 'Email must be valid'
})

extend('min', {
    validate(value, {min}) {
        return value.length >= min
    },
    params: ['min']
})

extend('max', {
    validate(value, {max}) {
        return value.length <= max
    },
    params: ['max']
})

extend('minMax', {
    validate(value, {min, max}) {
        return value.length >= min && value.length <= max
    },
    params: ['min', 'max']
})

extend('isPostalCode', {
    validate(value) {
        return validator.isPostalCode(value, 'any')
    }
})

extend('isNotIp', {
    validate(value) {
        return !validator.isIP(value)
    }
})

extend('isURL', {
    validate(value) {
        return validator.isURL(value)
    }
})

extend('isNumeric', {
    validate(value) {
        return validator.isNumeric(value + '')
    }
})

extend('isEmail', {
    validate(value) {
        return validator.isEmail(value)
    }
})

extend('isHexColor', {
    validate(value) {
        return validator.isHexColor(value)
    }
})

extend('isChecked', {
    validate(value) {
        return value
    }
})

extend('betweenDeals', {
    ...between
})

extend('betweenNumbers', {
    ...between
})

extend('isVat', {
    validate(value) {
        return /^((AT)(U\d{8})|(BE)(0\d{9})|(BG)(\d{9,10})|(CY)(\d{8}[LX])|(CZ)(\d{8,10})|(DE)(\d{9})|(DK)(\d{8})|(EE)(\d{9})|(EL|GR)(\d{9})|(ES)([\dA-Z]\d{7}[\dA-Z])|(FI)(\d{8})|(FR)([\dA-Z]{2}\d{9})|(HU)(\d{8})|(IE)(\d{7}[A-Z]{2})|(IT)(\d{11})|(LT)(\d{9}|\d{12})|(LU)(\d{8})|(LV)(\d{11})|(MT)(\d{8})|(NL)(\d{9}(B\d{2}|BO2))|(PL)(\d{10})|(PT)(\d{9})|(RO)(\d{2,10})|(SE)(\d{12})|(SI)(\d{8})|(SK)(\d{10}))$/.test(
            value
        )
    }
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

/*
extend('min', {
    validate(value, { max }) {
        return value.length <= max
    },
    params: ['min']
})

extend('max', {
    validate(value, { max }) {
        return value.length <= max
    },
    params: ['max']
})

extend('minMax', {
    validate(value, { min, max }) {
        return value.length >= min && value.length <= max
    },
    params: ['min', 'max']
})
*/

Vue.config.productionTip = false

const vue = new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App)
}).$mount('#app')

export default vue
