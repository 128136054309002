export default {
    async createTask({dispatch}, body) {
        return fetch(`${Window.$apiUrl}/api/v1/rest/products/cyberrisk-analyses`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(body)
        })
    },

    async createFastScanTask({dispatch}, body) {
        return fetch(`${Window.$apiUrl}/api/v1/rest/products/cyberrisk-analyses/fast-scan`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(body)
        })
    },

    async createTaskBulk(domains) {
        return fetch(`${Window.$apiUrl}/api/rest/task/bulk-create`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(domains)
        })
    },

    async loadAllDomains({dispatch, commit}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/task/allDomains`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const allDomains = await response.json()
            commit('setDomainItems', allDomains)
        }
    },

    async loadTasksOfDomain({dispatch, commit}, domain) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/task/loadTasksOfDomain/${domain}`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const tasks = await response.json()
            commit('setTasksOfDomain', tasks)
        }
    },

    async loadAllCategoryIds({dispatch, commit}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/categories/loadAllCategoryIds/`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const categories = await response.json()
            commit('setCategoryIds', categories)
        }
    },

    async refreshTask({dispatch}, taskId) {
        return fetch(`${Window.$apiUrl}/api/rest/task/${taskId}/refresh`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include'
        })
    },

    async deleteScan({dispatch}, scanId) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/scans/${scanId}/delete`, {
            method: 'POST',
            credentials: 'include'
        })
        if (response.ok) {
            return true
        }
        return true
    },

    async reparseTask({dispatch}, taskId) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/task/${taskId}/reparse`, {
            method: 'POST',
            credentials: 'include'
        })
        if (response.ok) {
            return true
        }
        return false
    },

    async deleteTask({dispatch}, taskId) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/task/${taskId}/delete`, {
            method: 'POST',
            credentials: 'include'
        })
        if (response.ok) {
            return true
        }
        return false
    },

    async getRerateLockStatus({dispatch, commit}, taskId) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/task/${taskId}/get-rerate-lock-status`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const status = await response.json()
            commit('setRerateLockStatus', status.rerateLock)
            return true
        }
        return false
    },

    async updateRerateLockStatus({dispatch, commit}, {taskId, rerateLockStatus}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/task/${taskId}/update-rerate-lock-status`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({rerateLockStatus: rerateLockStatus})
        })
        if (response.ok) {
            commit('setRerateLockStatus', rerateLockStatus)
            return true
        }
        return false
    },

    async addMonitoring({dispatch}, {taskId, interval}) {
        return fetch(`${Window.$apiUrl}/api/rest/task/${taskId}/add-monitoring?interval=${interval}`, {
            method: 'POST',
            credentials: 'include'
        })
    },

    async createCategory({dispatch}, {productId, category}) {
        category.config = category.Config_Mappings[0].Config.settings
        delete category.Config_Mappings
        return await fetch(`${Window.$apiUrl}/api/rest/risk-management/${productId}/add-category`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(category)
        })
    },

    async editCategory({dispatch}, category) {
        category.configId = category.Config_Mappings[0].Config.id
        category.config = category.Config_Mappings[0].Config.settings
        return await fetch(`${Window.$apiUrl}/api/rest/risk-management/edit-category/${category.id}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(category)
        })
    },

    async deleteCategory({dispatch}, category) {
        return await fetch(`${Window.$apiUrl}/api/rest/risk-management/delete-category/${category.id}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(category)
        })
    },

    async createSupplierTask({dispatch}, {productId, categoryId, task}) {
        task.categoryId = categoryId
        return await fetch(`${Window.$apiUrl}/api/rest/risk-management/${productId}/create-for-risk-list`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(task)
        })
    },

    async updateSupplierTask({dispatch}, {productId, task}) {
        return await fetch(`${Window.$apiUrl}/api/rest/risk-management/${productId}/update-task/${task.id}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(task)
        })
    },

    async createComparisionTask({dispatch}, {productId, categoryId, task}) {
        task.categoryId = categoryId
        return await fetch(`${Window.$apiUrl}/api/rest/risk-comparison/${productId}/create-for-risk-list`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(task)
        })
    },

    async createComparisionTasks({dispatch}, {productId, tasks}) {
        return await fetch(`${Window.$apiUrl}/api/rest/risk-comparison/${productId}/import-for-risk-list`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(tasks)
        })
    },

    async updateComparisionTask({dispatch}, {productId, task}) {
        return await fetch(`${Window.$apiUrl}/api/rest/risk-comparison/${productId}/update-task/${task.id}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(task)
        })
    },

    async changeCategoryOfTask({dispatch}, {productId, taskIds, categoryId}) {
        return await fetch(`${Window.$apiUrl}/api/rest/risk-management/${productId}/change-category-to/${categoryId}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({taskIds})
        })
    },

    async deleteSupplierTasks({dispatch}, {productId, taskIds}) {
        return await fetch(`${Window.$apiUrl}/api/rest/risk-management/${productId}/delete-supplier-tasks`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({taskIds})
        })
    }
}
