export default {
    async loadConfig({}, id) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/configs/${id}/load`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            let config = await response.json()
            config.type = getConfigType(config)
            config.standard = config.type == 'DEFAULT' ? true : false
            return config
        }
        return false
    },

    async loadConfigs({dispatch, commit}) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/configs/all`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            let configs = await response.json()

            configs = configs.map((config) => {
                config.type = getConfigType(config)
                config.standard = config.type == 'DEFAULT' ? true : false
                return config
            })

            commit('setConfigs', configs)
        }
    },

    async configUpdateStatus({}, id) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/configs/${id}/checkUpdateStatus`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            return await response.json()
        }
        return false
    },

    async createConfig({state, dispatch}, config) {
        config.type = getConfigType(config)
        config.standard = config.type == 'DEFAULT' ? true : false
        const response = await fetch(`${Window.$apiUrl}/api/rest/configs/create`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(config)
        })
        if (response.ok) {
            dispatch('loadConfigs')
        }
        return response
    },

    async updateConfig({state, dispatch}, config) {
        config.type = getConfigType(config)
        config.standard = config.type == 'DEFAULT' ? true : false
        const response = await fetch(`${Window.$apiUrl}/api/rest/configs/${config.id}/update`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(config)
        })
        if (response.ok) {
            dispatch('loadConfigs')
        }
        return response
    },

    async deleteConfig({state, dispatch}, configId) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/configs/${configId}/delete`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include'
        })
        if (response.ok) {
            dispatch('loadConfigs')
        }
        return response
    },

    async createTagGroup({state, commit}, {taskId, names, tags}) {
        const response = await fetch(`${Window.$apiUrl}/api/v1/rest/configs/groups/${taskId}/`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                names: names,
                tags: tags
            })
        })
        if (response.ok) {
            commit('setScanConfig', {Config: await response.json()})
            return true
        }

        return false
    },

    async updateTagGroup({state, commit}, {taskId, groupId, name, tags}) {
        const response = await fetch(`${Window.$apiUrl}/api/v1/rest/configs/groups/${taskId}/${groupId}/`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify({
                name: name,
                tags: tags
            })
        })
        if (response.ok) {
            commit('setScanConfig', {Config: await response.json()})
            return true
        }

        return false
    },

    async updateDeadlineInTagGroup({state, commit}, {taskId, groupId, deadlineData}) {
        const response = await fetch(`${Window.$apiUrl}/api/v1/rest/configs/groups/${taskId}/${groupId}/deadline`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify({
                deadlineData: deadlineData
            })
        })
        if (response.ok) {
            commit('setScanConfig', {Config: await response.json()})
            return true
        }

        return false
    },

    async deleteTagGroup({state, commit}, {taskId, groupId}) {
        const response = await fetch(`${Window.$apiUrl}/api/v1/rest/configs/groups/${taskId}/${groupId}/`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            credentials: 'include'
        })
        if (response.ok) {
            commit('setScanConfig', {Config: await response.json()})
            return true
        }
        return false
    },

    async updateTaskConfig({state, commit}, params) {
        const reportConfig = state.fpConfig.reportConfig
        const serverConfigChanges = state.fpConfig.serverConfigChanges
        const configTags = state.tags.configTags
        if (params.taskId && reportConfig && configTags) {
            // Transform List into Object with correct structure
            const fpConfig = {}
            for (const c of reportConfig) {
                // Use UUID as a key
                const uuidKey = c.item.uuid
                fpConfig[uuidKey] = {}
                // Remove information for snackBar and save itemGrade to remove it from object if it has not changed
                const itemGrade = c.item.grade
                delete c.item
                delete c.useTitle
                delete c.type
                delete c.param
                // Iterate over keys (all, IPs, Locations)
                for (const key of Object.keys(c)) {
                    // Now remove everything which is not set by the user
                    if (itemGrade === c[key].grade) {
                        //delete c[key].grade do not delete, because we want to be able to fixate it always
                    }
                    if (!c[key].delete) {
                        delete c[key].delete
                    }
                    if (c[key].falsePositive == undefined) {
                        delete c[key].falsePositive
                    }
                    if (!c[key].deleteConfig) {
                        delete c[key].deleteConfig
                    }
                    if (c[key].reason.length === 0) {
                        delete c[key].reason
                    }
                    if (c[key].contact.length === 0) {
                        delete c[key].contact
                    }
                    if (!c[key].parentUUID) {
                        delete c[key].parentUUID
                    }
                    delete c[key].parentIp

                    if (c[key]) {
                        fpConfig[uuidKey][key] = c[key]
                    }
                }
            }

            const tags = {}
            for (const tagItem of configTags) {
                /*  if(tagItem.mode=="remove") {
                    if(Array.isArray(tags[tagItem.hostIp]))
                        tags[tagItem.hostIp]=tags[tagItem.hostIp].filter((tag)=>tag!==tagItem.tag)

                }else{*/
                if (tags[tagItem.hostIp]) {
                    tags[tagItem.hostIp].push(tagItem.tag)
                } else {
                    tags[tagItem.hostIp] = [tagItem.tag]
                }
            }

            const response = await fetch(`${Window.$apiUrl}/api/rest/configs/task/${params.taskId}/update`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    config: {
                        fpConfig: fpConfig,
                        serverConfigChanges: serverConfigChanges,
                        tags: tags
                    },
                    scanId: params.scanId
                })
            })
            if (response.ok) {
                //commit('resetConfig')
                return true
            }
        }
        return false
    },

    async updateTagFilterConfig({state, commit}, params) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/configs/task/${params.taskId}/filter/update`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                config: params.config,
                scanId: params.scanId
            })
        })
        if (response.ok) {
            return true
        }
        return false
    },

    async removeTagRemote({state, commit}, params) {
        if (params.scanId && params.tagItem) {
            const response = await fetch(`${Window.$apiUrl}/api/rest/configs/task/${params.scanId}/tag/${params.tagItem.hostIp}/delete`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    tags: [params.tagItem.tag]
                })
            })
            if (response.ok) {
                // ToDo: Config restructuring, remove everything except settings
                commit('setScanConfig', {Config: await response.json()})
                return true
            }
        }

        return false
    },

    async removeTagsForIp({state, commit}, params) {
        if (params.taskId && params.hostIp) {
            const tags = []
            for (const tagItem of state.tags.configTags) {
                if (tagItem.hostIp === params.hostIp) {
                    tags.push(tagItem.tag)
                }
            }
            if (tags.length > 0) {
                const response = await fetch(`${Window.$apiUrl}/api/rest/configs/task/${params.taskId}/tag/${params.tagItem.hostIp}/delete`, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    credentials: 'include',
                    body: JSON.stringify({
                        tags: [tags]
                    })
                })
                if (response.ok) {
                    // ToDo: Config restructuring, remove everything except settings
                    commit('setScanConfig', {Config: await response.json()})
                    return true
                }
            }
        }
        return false
    }
}

function getConfigType(config) {
    if (!config) {
        return ''
    }
    if (config.domain) return 'DOMAIN'
    if (config.CompanyId) return 'COMPANY'
    if (config.CategoryId) return 'CATEGORY'
    if (config.ProductId) return 'PRODUCT'
    if (config.TaskId) return 'TASK'
    if (config.standard) return 'DEFAULT'
    if (config.ScanId) return 'SCAN'
}
