<template>
    <div v-if="isLocateRiskAdmin || isOrganizationalAdmin || hasAllUserGrantsOwnCompany || hasAllUserGrantsResponsibleCompanies">
        <UserDialog
            @save="(user) => saveUser(user)"
            v-if="showUserDialog"
            value
            :userUpdate="null"
            :editOwnUser="false"
            @input="showUserDialog = false"
            :addUserToCompanyId="getCompanyId"
            :no-user-grants="noUserGrants"
        />
        <v-snackbar v-model="error" content-class="white--text" :timeout="8000" color="#ff0e61">
            {{ $t('users.creation_error') }}

            <template v-slot:action="{attrs}">
                <v-btn text v-bind="attrs" @click="error = false"> Close </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar v-model="success" content-class="white--text" :timeout="3000" color="#36ddcf">
            {{ $t('users.creation_success') }}

            <template v-slot:action="{attrs}">
                <v-btn text v-bind="attrs" @click="success = false"> Close </v-btn>
            </template>
        </v-snackbar>

        <div class="ml-2">
            <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                    <v-btn @click="showUserDialog = true" icon v-bind="attrs" v-on="on" :disabled="disableButton"><v-icon>mdi-account-plus</v-icon></v-btn>
                </template>
                <span>{{ $t('users.addUserTooltip') }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import UserDialog from '../views/users/UserDialog'

export default {
    name: 'AddUserComponent',
    components: {UserDialog},
    props: {
        noUserGrants: {
            type: Boolean,
            required: false,
            default: false
        },
        disableButton: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            showUserDialog: false,
            success: false,
            error: false
        }
    },
    computed: {
        ...mapGetters(['isLocateRiskAdmin', 'isOrganizationalAdmin', 'hasAllUserGrantsResponsibleCompanies', 'hasAllUserGrantsOwnCompany', 'scanData']),
        getCompanyId() {
            return this.scanData?.managementInformation?.Task?.Category?.Product?.CompanyId ?? null
        }
    },
    methods: {
        ...mapActions(['createUser']),
        async saveUser(user) {
            this.showUserDialog = false
            const response = await this.createUser(user)
            if (response.ok) {
                const data = await response.json()
                this.success = true
                this.$emit('userAdded', data)
            } else {
                this.error = true
            }
        }
    }
}
</script>

<style scoped></style>
