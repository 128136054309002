import {api} from '@/new/helper/Http'

export default {
    async getReleaseNotes(params) {
        const {onlyIfNotSeen} = {onlyIfNotSeen: false, ...params}
        return api.get(`/release-notes?onlyIfNotSeen=${onlyIfNotSeen}`)
    },
    async deleteReleaseNote({id}) {
        return api.delete(`/release-notes`, {
            data: {
                id
            }
        })
    },
    async createReleaseNote({isPublic, onlyForPartner, messages}) {
        return api.post(`/release-notes`, {
            isPublic,
            onlyForPartner,
            messages
        })
    },
    async updateReleaseNote({id, isPublic, onlyForPartner, messages}) {
        return api.put(`/release-notes`, {
            id,
            isPublic,
            onlyForPartner,
            messages
        })
    },
    async seen() {
        return api.put(`/release-notes/seen`)
    }
}
