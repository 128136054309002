const {actions} = require('../../../../models/models/enums/').Actions
const {grants} = require('../../../../models/models/enums/').Grants

function isLocateRiskAdmin(state) {
    if (state.userData && state.userData.grants) {
        return state.userData.grants[grants.LOCATE_RISK_ADMIN]
    }
    return false
}

export default {
    isLocateRiskAdmin: isLocateRiskAdmin,

    canViewTrial(state) {
        if (
            state.userData.grants[grants.LOCATE_RISK_ADMIN] ||
            (state.userData.grants.specialGrants && state.userData.grants.specialGrants.includes(actions.VIEW_TRIAL))
        ) {
            return true
        }
        return false
    },

    hasNoPartner(state, getters) {
        return !getters.currentUserOrganization?.PartnerId
    },

    isPartner(state, getters) {
        const currentUserOrganization = getters.currentUserOrganization
        if (!currentUserOrganization) {
            return false
        }
        return currentUserOrganization.isPartner
    },

    isItemShared: (state) => (id) => {
        for (const grant of state.userData?.grants) {
            if (grant.ProductId === id || grant.CategoryId === id || grant.TaskId === id || grant.ScanId === id) {
                if (grant.grantType === 'READ_ITEM') {
                    return true
                }
            }
        }
        return false
    },

    currentUserOrganization(state) {
        if (state.organization) {
            return state.organization
        }
        return null
    },

    currentUser(state) {
        if (state.userData) {
            return state.userData
        }
        return null
    },

    isOrganizationalAdmin(state) {
        if (state.userData && state.userData.grants) {
            return state.userData.grants[grants.ORGANIZATIONAL_ADMIN]
        }
        return false
    },

    isResponsibleForAllCustomers(state) {
        if (state.userData && state.userData.grants) {
            return state.userData.grants[grants.RESPONSIBLE_FOR_ALL_CUSTOMERS_OF_COMPANY]
        }
        return false
    },

    hasAllUserGrantsOwnCompany(state) {
        if (state.userData && state.userData.grants) {
            return state.userData.grants[grants.ALL_USER_GRANTS_ON_OWN_COMPANY]
        }
        return false
    },

    hasAllUserGrantsResponsibleCompanies(state) {
        if (state.userData && state.userData.grants) {
            return state.userData.grants[grants.ALL_USER_GRANTS_ON_RESPONSIBLE_COMPANIES]
        }
        return false
    },

    hasAllUserGrants(state) {
        if (state.userData && state.userData.grants) {
            return state.userData.grants[grants.ALL_USER_GRANTS_ON_RESPONSIBLE_COMPANIES] && state.userData.grants[grants.ALL_USER_GRANTS_ON_OWN_COMPANY]
        }
        return false
    },

    hasAllTaskGrantsOwnCompany(state) {
        if (state.userData && state.userData.grants) {
            return state.userData.grants[grants.ALL_TASK_GRANTS_ON_OWN_COMPANY]
        }
        return false
    },

    hasAllTaskGrantsResponsibleCompanies(state) {
        if (state.userData && state.userData.grants) {
            return state.userData.grants[grants.ALL_TASK_GRANTS_ON_RESPONSIBLE_COMPANIES]
        }
        return false
    },

    hasAllTaskGrants(state) {
        if (state.userData && state.userData.grants) {
            return (
                state.userData.grants[grants.ALL_TASK_GRANTS_ON_RESPONSIBLE_COMPANIES] &&
                state.userData.grants[grants.ALL_TASK_GRANTS_ON_OWN_COMPANY] &&
                state.userData.grants[grants.CAN_VIEW_ALL_TASKS_FROM_RESPONSIBLE_COMPANIES] &&
                state.userData.grants[grants.CAN_VIEW_ALL_TASKS_FROM_OWN_COMPANY]
            )
        }
        return false
    },

    hasAllScanGrantsOwnCompany(state) {
        if (state.userData && state.userData.grants) {
            return state.userData.grants[grants.ALL_SCAN_GRANTS_ON_OWN_COMPANY]
        }
        return false
    },

    hasAllScanGrantsResponsibleCompanies(state) {
        if (state.userData && state.userData.grants) {
            return state.userData.grants[grants.ALL_SCAN_GRANTS_ON_RESPONSIBLE_COMPANIES]
        }
        return false
    },

    hasAllScanGrants(state) {
        if (state.userData && state.userData.grants) {
            return state.userData.grants[grants.ALL_SCAN_GRANTS_ON_RESPONSIBLE_COMPANIES] && state.userData.grants[grants.ALL_SCAN_GRANTS_ON_OWN_COMPANY]
        }
        return false
    },

    hasAllCompanyGrantsOwnCompany(state) {
        if (state.userData && state.userData.grants) {
            return state.userData.grants[grants.ALL_COMPANY_GRANTS_ON_OWN_COMPANY]
        }
        return false
    },

    hasAllCompanyGrantsResponsibleCompanies(state) {
        if (state.userData && state.userData.grants) {
            return state.userData.grants[grants.ALL_COMPANY_GRANTS_ON_RESPONSIBLE_COMPANIES]
        }
        return false
    },

    hasAllCompanyGrants(state) {
        if (state.userData && state.userData.grants) {
            return state.userData.grants[grants.ALL_COMPANY_GRANTS_ON_RESPONSIBLE_COMPANIES] && state.userData.grants[grants.ALL_COMPANY_GRANTS_ON_OWN_COMPANY]
        }
        return false
    },

    canViewAllTasks(state) {
        if (state.userData && state.userData.grants) {
            return (
                state.userData.grants[grants.CAN_VIEW_ALL_TASKS_FROM_RESPONSIBLE_COMPANIES] && state.userData.grants[grants.CAN_VIEW_ALL_TASKS_FROM_OWN_COMPANY]
            )
        }
        return false
    },

    canChangePricing(state) {
        if (state.userData?.grants?.userGrants?.includes(actions.MODIFY_GENERAL_PRICES) || isLocateRiskAdmin(state)) {
            return true
        }
        return false
    }
}
