<template>
    <Dialog
        v-model="dialog"
        @close="
            $emit('close', false)
            sharedLinkId = null
        "
        @cancel="
            $emit('close', false)
            sharedLinkId = null
        "
        @input="
            $emit('close', false)
            sharedLinkId = null
        "
        :title="title"
        width="1000"
    >
        <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>

        <v-snackbar absolute color="primary" v-model="showSnackbar" top
            ><span style="color: white">{{ $t('shareLink.saved') }}</span></v-snackbar
        >

        <v-card outlined>
            <v-expansion-panels v-model="panels" accordion>
                <v-expansion-panel>
                    <v-expansion-panel-header v-slot="{open}">
                        <h3 v-if="!open">{{ $t('shareLink.shareWithLink') }}</h3>
                        <h3 v-if="open" class="grey--text">{{ $t('shareLink.shareWithLink') }}</h3>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content eager>
                        <div class="wrapper">
                            <v-alert v-if="groupId" type="info">
                                {{ $t('shareLink.filterReminder') }}
                            </v-alert>

                            <div>
                                <strong>{{ $t('shareLink.password') }}</strong>
                                <v-text-field v-model="password" dense hide-details outlined></v-text-field>
                            </div>
                            <div v-if="taskId && !scanId && canViewTrial && isManagementOverview">
                                <v-checkbox v-model="viewTrial" :label="$t('shareLink.viewTrial')"></v-checkbox>
                            </div>
                            <div>
                                <strong>{{ $t('shareLink.whiteLabel') }}</strong>
                                <v-select
                                    :items="organizations"
                                    v-model="selectedOrganizationStyle"
                                    item-value="id"
                                    :item-text="(orga) => orga.name"
                                ></v-select>
                            </div>

                            <div style="display: flex; flex-direction: column">
                                <strong>{{ $t('shareLink.expires') }}</strong>
                                <v-date-picker v-model="expires" header-color="white" :flat="true" :allowed-dates="(val) => dateAllowed(val)"></v-date-picker>
                            </div>
                            <v-btn v-if="!editEnabled" color="primary" @click="createLink()" :disabled="disableSharing">{{
                                $t('shareLink.generateLink')
                            }}</v-btn>
                            <v-row v-if="editEnabled" class="d-flex justify-around">
                                <v-col>
                                    <v-btn block color="primary" elevation="0" @click="updateLink()">{{ $t('shareLink.updateLink') }}</v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn block outlined @click="cancelEdit()">{{ $t('shareLink.cancelUpdateLink') }}</v-btn>
                                </v-col>
                            </v-row>

                            <div v-if="sharedLinkId" class="button-grid">
                                <div>
                                    <strong>{{ $t('shareLink.link') }}</strong>
                                    <v-text-field :value="sharedLinkId" readonly dense hide-details outlined></v-text-field>
                                </div>
                                <v-btn
                                    style="align-self: end; justify-self: start"
                                    icon
                                    @click="
                                        copyToClipboard(sharedLinkId)
                                        showSnackbar = true
                                    "
                                >
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                            </div>

                            <div v-if="shareSupplier">
                                <v-checkbox style="padding-top: 0px" hide-details :label="$t('shareLink.isSupplier')" v-model="isSupplier"> </v-checkbox>
                            </div>

                            <div v-if="sharedLinks.length">
                                <v-data-table :headers="tableHeaders" :items="sharedLinks" disable-pagination hide-default-footer>
                                    <template v-slot:body="{}">
                                        <tbody>
                                            <tr v-for="link in sharedLinks" :key="link.id" :class="link.id == editId && 'success lighten-5'">
                                                <td>{{ link.createdAt ? formatTimestamp(link.createdAt, 'DD.MM.YYYY HH:mm') : '-' }}</td>
                                                <td>
                                                    <a :href="prepareLink(link.id)" target="_blank">{{ prepareLink(link.id) }}</a>
                                                </td>
                                                <td>{{ link.expires ? formatTimestamp(link.expires, 'DD.MM.YYYY') : '-' }}</td>
                                                <td>{{ getOrganizationNameFromId(link.CompanyId) }}</td>
                                                <td>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <v-icon @click="deleteLink(link.id)" v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                                        </template>
                                                        <span>{{ $t('actions.deleteShareLink') }}</span>
                                                    </v-tooltip>
                                                </td>
                                                <td>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <v-icon @click="editLink(link)" v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
                                                        </template>
                                                        <span>{{ $t('actions.editShareLink') }}</span>
                                                    </v-tooltip>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-data-table>
                            </div>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header v-slot="{open}">
                        <h3 v-if="!open">{{ $t('shareLink.shareToUser') }}</h3>
                        <h3 v-if="open" class="grey--text">{{ $t('shareLink.shareToUser') }}</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content eager>
                        <div style="display: flex; flex-direction: column">
                            <div>
                                <strong>{{ $t('shareLink.usersAuthorized') }}</strong>
                                <v-list dense outlined min-height="15rem" max-height="15rem" class="overflow-y-auto" style="margin-top: 0.5rem">
                                    <v-list-item v-for="user in displayedUsers.sharedUsers" :key="user.id">
                                        <v-list-item-title class="user-list-item">{{ getDisplayedUserName(user) }}</v-list-item-title>

                                        <v-spacer></v-spacer>
                                        <v-btn-toggle
                                            v-if="recipients && recipients[user.email]"
                                            v-model="recipients[user.email].events"
                                            dense
                                            background-color="var(--v-success-base);"
                                            multiple
                                        >
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        elevation="0"
                                                        icon
                                                        color="success"
                                                        @click="updateNotifySubscroption(user.email, 0)"
                                                    >
                                                        <v-icon>mdi-file-refresh-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('NotifyEvents.AFTER_FINISHED') }}</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        elevation="0"
                                                        icon
                                                        :disabled="isManagementOverview"
                                                        color="success"
                                                        @click="updateNotifySubscroption(user.email, 1)"
                                                    >
                                                        <v-icon>mdi-alert-plus-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('NotifyEvents.NEW_CVES') }}</span>
                                            </v-tooltip>
                                        </v-btn-toggle>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn v-bind="attrs" v-on="on" elevation="0" icon color="grey" @click="unshareItemWithUser(user)">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('shareLink.removeUser') }}</span>
                                        </v-tooltip>
                                    </v-list-item>
                                    <v-list-item v-if="displayedUsers.sharedUsers.length === 0">
                                        <v-list-item-title style="text-align: center">{{ $t('shareLink.noUserAvailable') }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </div>

                            <v-divider style="margin-top: 1rem"></v-divider>

                            <div style="margin-top: 1rem">
                                <strong>{{ $t('shareLink.userShare') }}</strong>
                                <div style="display: flex; flex-direction: row; align-content: space-between; margin-top: 0.5rem">
                                    <v-autocomplete
                                        v-model="selectedShareUser"
                                        :filter="(item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.trim().toLocaleLowerCase()) > -1"
                                        :items="displayedUsers.notSharedUsers"
                                        :item-text="(item) => getDisplayedUserName(item)"
                                        :item-value="(item) => item"
                                        outlined
                                        dense
                                        hide-details
                                        clearable
                                        style="width: 80%"
                                        :placeholder="$t('shareLink.namePlaceholder')"
                                    />
                                    <v-btn color="primary" style="margin-left: 1rem" @click="shareItemWithUser()" :disabled="disableSharing">{{
                                        $t('shareLink.shareButton')
                                    }}</v-btn>
                                    <AddUserComponent @userAdded="(user) => userAdded(user)" :no-user-grants="noUserGrants" :disable-button="disableSharing" />
                                </div>
                            </div>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header v-slot="{open}">
                        <h3 v-if="!open">{{ $t('shareLink.emailTemplate.header') }}</h3>
                        <h3 v-if="open" class="grey--text">{{ $t('shareLink.emailTemplate.header') }}</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content eager>
                        <div style="display: flex; flex-direction: column">
                            <p>{{ $t('shareLink.emailTemplate.description') }}</p>
                            <br />
                            <v-card outlined>
                                <v-expansion-panels accordion>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <h4>{{ $t('shareLink.emailTemplate.reviewAndOpinion.subject') }}</h4>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <p>
                                                {{ $t('shareLink.emailTemplate.subjectCaption') + $t('shareLink.emailTemplate.reviewAndOpinion.subject') }}
                                            </p>
                                            <br />
                                            <!-- Completely predefined => fine -->
                                            <div v-html="$t('shareLink.emailTemplate.reviewAndOpinion.body')"></div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <h4>{{ $t('shareLink.emailTemplate.followUpEmail1.subject') }}</h4>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <p>
                                                {{ $t('shareLink.emailTemplate.subjectCaption') + $t('shareLink.emailTemplate.followUpEmail1.subject') }}
                                            </p>
                                            <br />
                                            <!-- Completely predefined => fine -->
                                            <div v-html="$t('shareLink.emailTemplate.followUpEmail1.body')"></div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <h4>{{ $t('shareLink.emailTemplate.followUpEmail2.subject') }}</h4>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <p>
                                                {{ $t('shareLink.emailTemplate.subjectCaption') + $t('shareLink.emailTemplate.followUpEmail2.subject') }}
                                            </p>
                                            <br />
                                            <!-- Completely predefined => fine -->
                                            <div v-html="$t('shareLink.emailTemplate.followUpEmail2.body')"></div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="showShareByEmail">
                    <v-expansion-panel-header v-slot="{open}">
                        <h3 v-if="!open">{{ $t('analysisOverview.shareLinkAutomation.title') }}</h3>
                        <h3 v-if="open" class="grey--text">{{ $t('analysisOverview.shareLinkAutomation.title') }}</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content eager>
                        <div style="display: flex; flex-direction: column">
                            <ShareByEmailComponent
                                :task-id="taskId"
                                @close="
                                    $emit('close', false)
                                    sharedLinkId = null
                                "
                            />
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
    </Dialog>
</template>

<script>
import Dialog from '@/components/dialogs/Dialog'
import {mapActions, mapGetters, mapState} from 'vuex'
import NotifyEvents from '../../../../models/models/enums/NotifyEvents'
import ReversedNotifyEvents from '../../../../models/models/enums/ReversedNotifyEvents'
import dayjs from 'dayjs'
import AddUserComponent from '../AddUserComponent.vue'
import ShareByEmailComponent from '../cyberriskAnalysis/ShareByEmailComponent.vue'

export default {
    name: 'ShareDialog',
    components: {Dialog, AddUserComponent, ShareByEmailComponent},
    async created() {
        this.loading = true
        this.panels = this.groupId ? [0] : []
        await this.loadLimitedOrganizationsInfo({includeLastLogin: false})
        await this.loadLinks()
        if (this.currentUserOrganization) {
            this.selectedOrganizationStyle = this.currentUserOrganization.id
        }
        this.loading = false
    },
    props: {
        shareSupplier: {
            type: Boolean,
            default: false
        },
        scanId: {
            type: String,
            required: false,
            default: null
        },
        taskId: {
            type: String,
            required: false,
            default: null
        },
        groupId: {
            type: String,
            required: false
        },
        isManagementOverview: {
            type: Boolean,
            required: false,
            default: false
        },
        noUserGrants: {
            type: Boolean,
            required: false,
            default: false
        },
        disableSharing: {
            type: Boolean,
            required: false,
            default: false
        },
        showShareByEmail: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            panels: [],
            title: this.$t('shareLink.title'),
            password: '',
            expires: '',
            isSupplier: false,
            showSnackbar: false,
            viewTrial: false,
            sharedLinkId: '',
            sharedLinks: [],
            selectedOrganizationStyle: null,
            users: {sharedUsers: [], notSharedUsers: []},
            displayedUsers: {sharedUsers: [], notSharedUsers: []},
            dialog: true,
            selectedShareUser: null,
            editEnabled: false,
            editId: undefined,
            recipients: undefined,
            loading: false
        }
    },
    computed: {
        ...mapGetters(['formatTimestamp', 'isJSON', 'currentUserOrganization', 'currentUser', 'canViewTrial', 'scanData']),
        ...mapState({
            organizations: (state) => state.organizationsLimited
        }),

        tableHeaders() {
            const result = []
            result.push({text: this.$t('shareLink.table.createdAt'), sortable: true})
            if (this.shareSupplier) {
                result.push({text: this.$t('shareLink.table.supplier'), sortable: false})
            }
            result.push(
                {text: this.$t('shareLink.table.link'), value: 'Shared_Link.id'},
                {text: this.$t('shareLink.expires'), value: 'expires'},
                {text: this.$t('shareLink.whiteLabel'), value: 'Shared_Link'},
                {text: '', width: 50},
                {text: '', width: 50}
            )
            return result
        }
    },
    methods: {
        ...mapActions([
            'shareItem',
            'unshareItem',
            'loadSharedLinks',
            'deleteSharedLink',
            'createSharedLink',
            'updateSharedLink',
            'loadSharedUsers',
            'loadLimitedOrganizationsInfo',
            'getCyberriskAnalysisNotifySubscriptions',
            'addNotifySubscriptionCyberriskAnalyses',
            'updateNotifySubscriptionCyberriskAnalyses',
            'deleteNotifySubscriptionCyberriskAnalyses'
        ]),

        async copyToClipboard(value) {
            if (!navigator.clipboard) {
                const el = document.createElement('textarea')
                el.value = value
                el.setAttribute('readonly', '')
                el.style.position = 'absolute'
                el.style.left = '-9999px'
                document.body.appendChild(el)
                el.select()
                document.execCommand('copy')
                document.body.removeChild(el)
            } else {
                await navigator.clipboard.writeText(value)
            }
            return true
        },

        async deleteLink(linkId) {
            this.loading = true
            await this.deleteSharedLink(linkId)
            await this.loadLinks()
            this.loading = false
        },

        async createLink() {
            let date = null
            if (this.expires) {
                const dateFormat = 'YYYY-MM-DD'
                date = dayjs(this.expires, dateFormat).format()
            }
            const sharedLink = await this.createSharedLink({
                scanId: this.scanId,
                taskId: this.taskId,
                groupId: this.groupId,
                expires: date,
                password: this.password,
                style: this.selectedOrganizationStyle,
                viewTrial: this.viewTrial
            })
            this.sharedLinkId = this.prepareLink(sharedLink.id)
            this.sharedLinks.unshift(sharedLink)
        },

        editLink(link) {
            this.title = this.$t('shareLink.title') + ' (' + this.$t('actions.editShareLink') + ')'
            this.editEnabled = true
            this.editId = link.id
            let date = ''
            if (link.expires) {
                const dateFormat = 'YYYY-MM-DD'
                date = dayjs(link.expires).format(dateFormat)
                this.expires = date
            }
            this.selectedOrganizationStyle = link.CompanyId
            this.viewTrial = link.viewTrial
        },

        async updateLink() {
            if (this.editId) {
                const updatedData = {
                    id: this.editId,
                    scanId: this.scanId,
                    taskId: this.taskId,
                    groupId: this.groupId,
                    expires: null,
                    password: this.password,
                    style: this.selectedOrganizationStyle,
                    viewTrial: this.viewTrial
                }
                if (this.expires) {
                    const dateFormat = 'YYYY-MM-DD'
                    updatedData.expires = dayjs(this.expires, dateFormat).format()
                }

                const sharedLink = await this.updateSharedLink(updatedData)
                this.sharedLinks = this.sharedLinks.map((link) => {
                    if (link.id == this.editId) return sharedLink
                    else return link
                })
            }
            this.cancelEdit()
        },

        cancelEdit() {
            this.editId = undefined
            this.expires = ''
            this.password = ''
            this.selectedOrganizationStyle = null
            this.viewTrial = false
            this.editEnabled = false
            this.title = this.$t('shareLink.title')
        },

        async loadLinks() {
            const itemId = this.scanId ? this.scanId : this.taskId
            const type = this.scanId ? 'scan' : 'task'

            let results = await Promise.all([
                this.loadSharedUsers({itemId, type, groupId: this.groupId}),
                this.loadSharedLinks({itemId, groupId: this.groupId, type}),
                this.loadRecipients()
            ])
            this.users = results[0]
            this.sharedLinks = results[1]
        },

        prepareLink(id) {
            return `${window.location.origin}/shared?id=${id}`
        },

        async unshareItemWithUser(user) {
            this.loading = true
            const type = this.scanId ? 'scan' : 'task'
            const itemId = {itemId: this.taskId ? this.taskId : this.scanData.taskId}
            if (type == 'scan') {
                itemId.itemId = this.scanId
                await this.unshareItem({...itemId, type, groupId: this.groupId, userId: user.id})
            } else {
                const recipientsToDelete = this.recipients[user.email] ? [this.recipients[user.email].id] : []
                if (this.groupId && recipientsToDelete.length > 0) {
                    await this.deleteNotifySubscriptionCyberriskAnalyses({taskId: itemId.itemId, notifySubscriptionIds: recipientsToDelete})
                }
                await this.unshareItem({...itemId, type, groupId: this.groupId, userId: user.id})
            }
            let results = await Promise.all([this.loadSharedUsers({...itemId, type, groupId: this.groupId}), this.loadRecipients()])
            this.users = results[0]

            this.loading = false
        },

        async shareItemWithUser() {
            if (this.selectedShareUser) {
                this.loading = true
                const type = this.scanId ? 'scan' : 'task'
                const itemId = {itemId: this.taskId ? this.taskId : this.scanData.taskId}
                if (type == 'scan') {
                    itemId.itemId = this.scanId
                    await this.shareItem({...itemId, type, groupId: this.groupId, userId: this.selectedShareUser.id})
                } else {
                    await this.shareItem({...itemId, type, groupId: this.groupId, userId: this.selectedShareUser.id})
                    const events = this.isManagementOverview ? [0] : [0, 1]
                    const recipientsToCreate = [
                        {
                            email: this.selectedShareUser.email,
                            events: events.map((event) => ReversedNotifyEvents[event]),
                            language: 'DE',
                            groupId: this.groupId
                        }
                    ]
                    if (this.groupId) {
                        const addNotify = await this.addNotifySubscriptionCyberriskAnalyses({taskId: itemId.itemId, notifySubscriptions: recipientsToCreate})
                    }
                }
                let results = await Promise.all([this.loadSharedUsers({...itemId, type, groupId: this.groupId}), this.loadRecipients()])

                this.users = results[0]

                this.selectedShareUser = null
                this.loading = false
            }
        },

        async loadRecipients() {
            const taskId = this.taskId ? this.taskId : this.scanData.taskId
            const response = await this.getCyberriskAnalysisNotifySubscriptions({cyberriskanalysisId: taskId, groupId: this.groupId, withoutGroup: false})
            const data = await response.json()
            const recipients = {}
            if (data.rows.length > 0) {
                data.rows[0].NotifySubscriptions.forEach((recipient) => {
                    recipients[recipient.email] = {
                        id: recipient.id,
                        email: recipient.email,
                        events: recipient.events.map((event) => NotifyEvents[event]),
                        language: recipient.language.toUpperCase(),
                        groupId: recipient.groupId
                    }
                })
            }
            this.recipients = recipients
        },

        dateAllowed(date) {
            if (date) {
                return dayjs(date, 'YYYY-MM-DD').isAfter(dayjs())
            }
            return false
        },

        getOrganizationNameFromId(id) {
            if (this.organizations.length > 0 && id) {
                for (const orga of this.organizations) {
                    if (orga.id === id) {
                        return orga.name
                    }
                }
            }
            return '-'
        },

        getDisplayedUserName(user) {
            return '(' + user.companyName + ') ' + user.givenName + ' ' + user.surname
        },

        async updateNotifySubscroption(email, notifyEvent) {
            this.loading = true
            const taskId = this.taskId ? this.taskId : this.scanData.taskId
            const recipient = []
            if (this.recipients[email]) {
                let events = this.recipients[email].events
                if (events.includes(notifyEvent)) {
                    events = events.filter((e) => e !== notifyEvent)
                } else {
                    events.push(notifyEvent)
                    events.sort()
                }
                // if (events.length > 0) {
                recipient.push({
                    id: this.recipients[email].id,
                    events: events.map((event) => ReversedNotifyEvents[event]),
                    language: this.recipients[email].language,
                    groupId: this.groupId
                })
                if (recipient.length > 0) {
                    const updateNotify = await this.updateNotifySubscriptionCyberriskAnalyses({taskId: taskId, notifySubscriptions: recipient})
                    if (updateNotify) await this.loadRecipients()
                }
                //}
            }
            this.loading = false
        },
        async userAdded(user) {
            const itemId = this.scanId ? this.scanId : this.taskId
            const type = this.scanId ? 'scan' : 'task'
            this.users = {sharedUsers: [], notSharedUsers: []}
            this.users = await this.loadSharedUsers({itemId, type, groupId: this.groupId})
            this.users.notSharedUsers.forEach((usr) => {
                if (usr.id == user.id) {
                    this.selectedShareUser = usr
                }
            })
        }
    },
    watch: {
        users() {
            this.displayedUsers.sharedUsers = this.users.sharedUsers.sort((a, b) => {
                if (a.companyName !== b.companyName) {
                    return a.companyName > b.companyName
                } else {
                    return a.surname > b.surname
                }
            })
            this.displayedUsers.notSharedUsers = this.users.notSharedUsers.sort((a, b) => {
                if (a.companyName !== b.companyName) {
                    return a.companyName > b.companyName
                } else {
                    return a.surname > b.surname
                }
            })

            if (!this.groupId) {
                this.displayedUsers.notSharedUsers = this.displayedUsers.notSharedUsers.filter((user) => {
                    if (this.currentUser && user.id === this.currentUser.id) {
                        return false
                    }
                    return true
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.wrapper {
    display: grid;
    grid-row-gap: 20px;

    .two-columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }

    .button-grid {
        display: grid;
        grid-template-columns: 1fr max-content;
    }
}
.user-list-item:hover {
    cursor: default;
}
</style>
