var apiUrl = process.env.VUE_APP_API_URL
Window.$apiUrl = apiUrl

import i18next from 'i18next'
import router from '../../router'
import Actions from '../../../../models/models/enums/ACTIONS'
import $vue from '../../main'
import {isLoggedIn} from '@/new/helper/Auth.js'
import {removeLoggedIn, setLoggedIn} from '../../new/helper/Auth'
import {getCurrentUser} from '@/services/UserService'
import ReleaseNotesService from '../../services/ReleaseNotesService'

export default {
    async giveScanConsent({state}, {token, justInformation = '', viewDetails}) {
        const response = await fetch(
            `${Window.$apiUrl}/api/v1/rest/organizations/${token}/give-consent${justInformation ? `?just-information=${justInformation}` : ''}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'PUT',
                credentials: 'include',
                body: JSON.stringify({
                    viewDetails: viewDetails
                })
            }
        )
        if (!response.ok) {
            throw new Error(response.status)
        }
        return await response.json()
    },

    async login({state, commit, dispatch, getters}, {email, password, totp}) {
        function b64EncodeUnicode(str) {
            return btoa(
                encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
                    return String.fromCharCode('0x' + p1)
                })
            )
        }

        const request = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Basic ' + b64EncodeUnicode(`${email.toLowerCase()}:${password}`),
                'totp-token': totp
            },
            method: 'POST',
            credentials: 'include'
        }

        const response = await fetch(`${Window.$apiUrl}/auth/login`, request)

        if (response.status === 429) {
            throw new Error('rateLimited')
        }

        const json = await response.json()

        if (!response.ok) {
            if (json?.totp) {
                return json.totp
            } else {
                throw new Error()
            }
        }

        setLoggedIn()
        commit('setUserData', json.userDetails)
        dispatch('loadUsers')
        //dispatch('loadOrganizations')
        dispatch('loadLoginStyle', json.userDetails.companyId)

        i18next.changeLanguage(json.userDetails.language)
        $vue.$vuetify.lang.current = json.userDetails.language

        // ToDo: remove duplicated code (login, restoreTokenFromCookie)
        if (json.userDetails.companyId) {
            const organizationResponse = await fetch(`${Window.$apiUrl}/api/rest/organization/${json.userDetails.companyId}`, {
                method: 'GET',
                credentials: 'include'
            })

            if (organizationResponse.ok) {
                commit('setOrganization', await organizationResponse.json())
            }
        }
    },

    async restoreTokenFromCookie({commit, state, dispatch, actions}) {
        if (!isLoggedIn()) {
            // restore token from cookie
            const response = await fetch(`${Window.$apiUrl}/auth/restore-token-from-cookie`, {
                credentials: 'include'
            })
            if (response.status === 200) {
                setLoggedIn()
            } else {
                removeLoggedIn()
                return
            }
        }
        try {
            const userData = (await getCurrentUser()).data
            commit('setLanguage', i18next.language)
            commit('setUserData', userData.userDetails)
            setLoggedIn()
            const [releaseNotes] = await Promise.all([
                (async () => {
                    let releaseNotes
                    try {
                        releaseNotes = (await ReleaseNotesService.getReleaseNotes({onlyIfNotSeen: true})).data
                    } catch {}
                    return releaseNotes
                })(),
                (async () => {
                    // Load User Organization
                    if (userData.userDetails.companyId) {
                        const organizationResponse = await fetch(`${Window.$apiUrl}/api/rest/organization/${userData.userDetails.companyId}`, {
                            method: 'GET',
                            credentials: 'include'
                        })

                        if (organizationResponse.ok) {
                            commit('setOrganization', await organizationResponse.json())
                        }
                    }
                })(),
                dispatch('loadUsers'),
                dispatch('loadLoginStyle', userData.userDetails.companyId)
            ])

            //await dispatch('loadOrganizations')
            i18next.changeLanguage(userData.userDetails.language)
            $vue.$vuetify.lang.current = userData.userDetails.language

            const fullPath = window.localStorage.getItem('fullPath')
            const force = window.localStorage.getItem('forceFullPath')
            if (fullPath) {
                window.localStorage.removeItem('forceFullPath')
                window.localStorage.removeItem('fullPath')
                try {
                    if (fullPath !== '/login') {
                        if (releaseNotes && releaseNotes.length > 0) {
                            if (fullPath) {
                                await router.push({name: 'RELEASE_NOTES', params: {propReleaseNotes: releaseNotes}})
                                // await router.push({name: 'RELEASE_NOTES', params: {propReleaseNotes: releaseNotes, backRoute: fullPath}})
                            } else {
                                await router.push({name: 'RELEASE_NOTES', params: {propReleaseNotes: releaseNotes}})
                            }
                        } else {
                            if (fullPath.includes('password-reset') || (fullPath.includes('/vendor-risk-management/supplier/') && !force)) {
                                await router.push('/')
                            } else {
                                await router.push(fullPath)
                            }
                        }
                    } else {
                        if (releaseNotes && releaseNotes.length > 0) {
                            await router.push({name: 'RELEASE_NOTES', params: {propReleaseNotes: releaseNotes}})
                        }
                    }
                } catch (err) {}
            }
        } catch (err) {
            window.localStorage.setItem('fullPath', window.location.pathname)
            removeLoggedIn()
            if (
                err?.response?.status &&
                err.response.status === 401 &&
                router.currentRoute.name !== 'Login' &&
                router.currentRoute.name !== 'PasswordReset' &&
                router.currentRoute.name !== 'GIVE_CONSENT' &&
                window.location.pathname !== '/shared' &&
                !window.location.pathname.startsWith('/print') &&
                !window.location.pathname.startsWith('/vendor-risk-management/supplier') &&
                router.currentRoute.name !== 'Unsubscribe'
            ) {
                const fullPath = window.localStorage.getItem('fullPath')
                await router.push({name: 'Login', params: {requestedPath: fullPath}})
            }
        }
    },

    async logout({state}, {sessionExpired} = {}) {
        const companyId = state.userData.companyId
        await fetch(`${Window.$apiUrl}/auth/logout`, {
            method: 'POST',
            credentials: 'include'
        })
        window.location = `/login?companyId=${companyId}${sessionExpired ? '&sessionExpired=1' : ''}`
    },

    async [Actions.USER_REQUEST_PASSWORD_RESET]({dispatch}, {email}) {
        await fetch(`${Window.$apiUrl}/auth/request-password-reset?email=${encodeURIComponent(email)}`, {
            method: 'POST'
        })
    },

    async hasGrant({}, params) {
        if (params.action && params.params) {
            const response = await fetch(`${Window.$apiUrl}/api/rest/user/grant/${params.action}/${JSON.stringify(params.params)}`, {
                method: 'GET',
                credentials: 'include'
            })
            if (response.ok) {
                return true
            }
        }
        return false
    }
}

function setCookie(cname, cvalue, exdays = 0) {
    var d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    var expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}
