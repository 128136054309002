export default {
    async POST({state}, {url, body, isFile = false}) {
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'x-auth-token': state.loggedIn
            },
            body: body
        }

        if (isFile) {
            delete request.headers['Content-Type']
            request.body = body
            request.enctype = 'multipart/form-data'
        }

        return fetch(url, request)
    },

    async GET({state}, {url}) {
        const request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'x-auth-token': state.loggedIn
            }
        }

        return fetch(url, request)
    }
}
