import Vue from 'vue'

export default {
    setMFASession(state, isMFASession) {
        Vue.set(state, 'mfa', isMFASession)
    },

    setUserData(state, userData) {
        Vue.set(state, 'userData', userData)
    }
}
