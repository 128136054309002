import { render, staticRenderFns } from "./VStep.vue?vue&type=template&id=13354609&scoped=true"
import script from "./VStep.vue?vue&type=script&lang=js"
export * from "./VStep.vue?vue&type=script&lang=js"
import style0 from "./VStep.vue?vue&type=style&index=0&id=13354609&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13354609",
  null
  
)

export default component.exports