<template>
    <div>
        <v-snackbar app top v-model="shareLinkEmailSent" timeout="5000" color="success">
            <span style="color: white">{{ $t('analysisOverview.shareLinkAutomation.success') }}</span>
        </v-snackbar>
        <v-card outlined>
            <v-card-text class="pt-5">
                <ValidationObserver ref="shareLinkEmailValidation">
                    <validation-provider rules="required|email" v-slot="{errors}">
                        <v-text-field
                            v-model="email"
                            :label="$t('analysisOverview.shareLinkAutomation.label')"
                            :placeholder="$t('analysisOverview.shareLinkAutomation.placeholder')"
                            outlined
                            :class="{invalid: errors.length > 0}"
                        ></v-text-field>
                        <div class="validation-error">{{ errors[0] }}</div>
                    </validation-provider>
                </ValidationObserver>
            </v-card-text>
            <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn color="grey" text class="body-2 font-weight-bold" @click="cancel">
                    {{ $t('analysisOverview.shareLinkAutomation.closeButton') }}
                </v-btn>
                <v-tooltip bottom>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn @click="sendShareLinkToEmail" color="primary" v-bind="attrs" v-on="on">
                            {{ $t('analysisOverview.shareLinkAutomation.buttonLabel') }}
                        </v-btn>
                    </template>
                    <span>{{ $t('analysisOverview.shareLinkAutomation.buttonTooltip') }}</span>
                </v-tooltip>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import {mapGetters, mapActions} from 'vuex'

export default {
    name: 'ShareByEmailComponent',
    props: {
        taskId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false
            },
            email: null,
            shareLinkEmailSent: false
        }
    },
    computed: {
        ...mapGetters(['scanData'])
    },
    methods: {
        ...mapActions(['createAndEmailSharedLink']),
        cancel() {
            this.$emit('close')
        },
        async sendShareLinkToEmail() {
            const valid = await this.$refs.shareLinkEmailValidation.validate()
            if (valid) {
                const sharedLink = await this.createAndEmailSharedLink({
                    scanId: undefined,
                    taskId: this.taskId,
                    groupId: undefined,
                    expires: dayjs().add(4, 'weeks').format('YYYY-MM-DD'),
                    password: '',
                    style: null,
                    viewTrial: true,
                    email: this.email,
                    language: this.$i18n.i18next.language
                })
                if (sharedLink) {
                    this.shareLinkEmailSent = true
                    this.email = null
                }
            }
        }
    }
}
</script>
