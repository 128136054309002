export default {
    getHTML: (state) => (link) => {
        return new Promise(async (res, rej) => {
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'x-auth-token': state.loggedIn
                }
            }

            const response = await fetch(link, request)

            const resultHTML = await response.text()

            res(resultHTML)
        })
    }
}
