import {DEFAULT_OPTIONS} from './constants'

export const delay = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

// add timeout if necessary
export const waitForElement = (selector, spinnerText, timeout = DEFAULT_OPTIONS.navigationTimeout) => {
    return new Promise((resolve) => {
        if (selector === undefined) {
            resolve(undefined)
        }

        const elem = document.querySelector(selector)

        if (elem || timeout == 0) {
            return resolve(elem)
        }

        let notFoundTimeout = 0 // pass by reference
        let spinnerTimeout = 0
        let spinner // spinner
        const observer = new MutationObserver((mutations) => {
            if (document.querySelector(selector)) {
                clearTimeout(notFoundTimeout)
                clearTimeout(spinnerTimeout)
                if (spinner) spinner.remove()

                resolve(document.querySelector(selector))
                observer.disconnect()
            }
        })

        observer.observe(document.body, {
            childList: true,
            subtree: true
        })

        // resolve after X seconds (0)
        // -1 = no timeout
        if (timeout > 0) {
            notFoundTimeout = setTimeout(() => {
                // console.log('Waiting for element timed-out, selector ' + selector + ' was not found.')
                clearTimeout(spinnerTimeout)
                if (spinner) spinner.remove()

                resolve(undefined)
                observer.disconnect()
            }, timeout)
        }

        if (spinnerText) {
            spinnerTimeout = setTimeout(() => {
                spinner = document.createElement('span')
                spinner.classList.add('wfe-spinner')
                const loader = document.createElement('span')
                loader.classList.add('wfe-loader')
                const hint = document.createElement('span')
                hint.classList.add('wfe-text')
                // hint.innerHTML = 'Waiting for next tour step to appear...<br/>Esc or refresh to cancel'
                hint.innerHTML = spinnerText
                //const cancel = document.createElement('button')
                //cancel.innerText = "Cancel"
                //hint.append(cancel)
                spinner.append(loader)
                spinner.append(hint)
                document.querySelector('.v-tour .v-overlay').append(spinner)
            }, 2000)
        }
    })
}
