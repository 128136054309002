import {delay} from '@/components/tours/shared/functions'

export default {
    async addSingleTourStep({state, dispatch, commit}, step) {
        //return //todo widget enable
        if (
            (!state.tours.singleTourSteps[step.stepId] && !state.tours.doNotShowAgain[step.stepId]) ||
            (step.inDialog && !state.tours.doNotShowAgain[step.stepId]) ||
            state.tours.allowShowAgain[step.stepId]
        ) {
            commit('addSingleTourStepInternal', step)

            clearTimeout(state.tours.awaitTourTimeoutId)
            // if a tour is about to start, it will add the pending single steps
            if (!state.tours.tourAboutToStart) {
                const newId = setTimeout(
                    () => {
                        if (!state.tours.tourAboutToStart) {
                            dispatch('getAndClearSingleTourSteps').then((waitingSteps) => {
                                dispatch('attachOrRunDefaultTour', waitingSteps)
                            })
                        }
                    },
                    step.showPermanentHelpStep || step.isObserved ? 0 : 500
                ) // approximate time to wait for more single steps to appear
                commit('setAwaitTourTimeoutId', newId)
            }
        }
    },
    async attachOrRunDefaultTour({state, dispatch, commit}, steps) {
        //return //todo widget enable
        let targetTour = 'default'

        if (state.tours.currentTour) {
            targetTour = state.tours.currentTour
        }

        commit('insertStepsIntoTour', {tour: targetTour, steps: steps, currentStep: state.tours.tourBase.currentStep})

        if (!state.tours.currentTour) {
            //state.tours.tours[targetTour].start();
            dispatch('startTourIfNotRunning', 'default')
            // commit("setCurrentTour", targetTour);
        }
    },
    async getAndClearSingleTourSteps({state, dispatch, commit}) {
        //return //todo widget enable
        const len = Object.keys(state.tours.singleTourSteps).length

        // also clear tour that was about to start
        commit('setTourAboutToStart', undefined)

        if (len > 0) {
            const steps = state.tours.singleTourSteps
            commit('clearSingleTourSteps')
            return Object.values(steps)
        } else {
            return []
        }
    },
    async applyTourClick({state, dispatch, commit}, clickTarget) {
        //return //todo widget enable
        // remove disallowed chars for object property
        const key = clickTarget.customKey.replace('/[. #:()!~]/g', '')
        if (!state.tours.clickTargets[key]) {
            if (clickTarget.clickOnce) {
                commit('setTourClick', key)
            }
            clickTarget.click()
        }
    },
    async startTourIfNotRunning({state, dispatch, commit}, tourName) {
        //return //todo widget enable
        if (!state.tours.tourAboutToStart !== tourName) {
            // do not start twice
            if (state.tours.tours[tourName] && state.tours.currentTour === tourName) return

            await delay(0)
            const canStartTour = await state.tours.tourBase.canStartTour(state.tours.tours[tourName])

            if ((state.tours.tourAboutToStart && canStartTour) || (state.tours.currentTour && canStartTour)) {
                // edge case (uncomment to support it)
                // merge tours, although this will likely not happen or should not
                //state.tours.tourBase.steps.push(...tour.steps);
                //state.tours.mergedTours.push(tour);
                // console.log(`Another tour is about to start or already running. Merging {tour.tourName} into {state.tours.tourAboutToStart}`);
                console.error('Another tour is about to start or already running: ' + state.tours.currentTour + '. Will not show: ' + tourName)
            } else if (canStartTour) {
                commit('setTourAboutToStart', tourName)

                state.tours.tourBase.tour = state.tours.tours[tourName]

                state.tours.tourBase.steps.push(...state.tours.tours[tourName].steps)

                dispatch('getAndClearSingleTourSteps').then((waitingSteps) => {
                    // attach them steps
                    state.tours.tourBase.steps.push(...waitingSteps)
                    // clear running timeouts
                    state.tours.tourBase.start()

                    commit('setCurrentTour', tourName)
                }) // when tour is started it will wait for first target to appear
            }
        }
    },
    async showAllTourStepsInUI({state, dispatch, commit}) {
        //return //todo widget enable
        if (state.tours.permanentHelpDots.length > 0) {
            // could be moved to separate mutation, but is just fine here for now
            state.tours.permanentHelpDots.forEach((dot) => dot.remove())
            state.tours.permanentHelpDots.splice(0)
            state.tours.permanentHelpVisible = false
        } else {
            // check for running tour - > button cannot be pressed then anyways
            // overlay disabling controls

            let numStepsToShow = 0

            // combine all steps
            let allSteps = Object.values(state.tours.tours)
                .map((tour) => tour.steps)
                .flat(1)
                .concat(state.tours.mountedSteps)

            // TODO maybe this can be placed somewhere else instead of state
            allSteps
                .filter((step) => {
                    // console.log(step);
                    const target = typeof step.target === 'string' ? document.querySelector(step.target) : step.target
                    if (target) {
                        const rect = target.getBoundingClientRect()
                        const testTarget = document.elementFromPoint(rect.x + rect.width / 2, rect.y + rect.height / 2)

                        if (testTarget != null && rect != testTarget && !testTarget.contains(target) && !target.contains(testTarget)) {
                            // element is hidden behind another -> do not show
                            return false
                        }

                        // carry target
                        step.targetRef = target

                        return true
                    }

                    return false
                })
                .forEach((step) => {
                    // first filter because else the help buttons would overlap

                    numStepsToShow++

                    const newChild = document.createElement('div')
                    newChild.classList.add('help-dot')
                    newChild.innerHTML = `
                <div class="help-dot-inner">
                    <i aria-hidden="true" class="notranslate mdi mdi-help-circle-outline theme--light"></i>
                </div>
                <div class="help-dot-ring"></div>
            `
                    newChild.onclick = (event) => {
                        event.stopPropagation()

                        dispatch('showPermanentHelpStep', step)
                    }

                    state.tours.permanentHelpDots.push(newChild)
                    document.body.append(newChild)

                    // carried from above
                    const target = step.targetRef
                    step.targetRef = undefined
                    const targetRect = target.getBoundingClientRect()
                    const childRect = newChild.getBoundingClientRect()
                    newChild.style.left = targetRect.left + targetRect.width / 2 - childRect.width / 2 + 'px' // With Offset Of 20px for correct postition
                    newChild.style.top = window.scrollY + targetRect.top + targetRect.height / 2 - childRect.height / 2 + 'px'
                })

            if (numStepsToShow > 0) {
                state.tours.permanentHelpVisible = true
            } else {
                commit('showDefaultSnackbar', 'No help tours are available here.')
            }
        }
        // get all tours and single steps
        // find elements and attach a dot with click listener
        // transparent overlay which will hide dots on click
    },
    async showPermanentHelpStep({state, dispatch, commit}, step) {
        //return //todo widget enable
        state.tours.permanentHelpDots.forEach((dot) => dot.remove())
        // TODO commit function...?
        state.tours.permanentHelpDots.splice(0)
        state.tours.permanentHelpVisible = false

        state.tours.doNotShowAgain[step.stepId] = false
        step.showPermanentHelpStep = true
        dispatch('addSingleTourStep', step)
    }
}
