module.exports = {
    createUserFrame,
    createLocateRiskAdminFrame,
    createOrganizationalAdminFrame
}

const {grants} = require('../../../../models/models/enums/').Grants

function createLocateRiskAdminFrame() {
    return createUserFrame(true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true)
}

function createOrganizationalAdminFrame() {
    return createUserFrame(false, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true)
}

function createUserFrame(
    locateRiskAdmin = false,
    organizationalAdmin = false,
    responsibleForAllCustomersOfCompany = false,
    allCompanyGrantsOwnCompany = false,
    allCompanyGrantsResponsibleCompanies = false,
    allUserGrantsOwnCompany = false,
    allUserGrantsResponsibleCompanies = false,
    allTaskGrantsOwnTasks = false,
    allTaskGrantsResponsibleCompanies = false,
    canViewAllTasksOwnCompany = false,
    canViewAllTasksResponsibleCompanies = false,
    allScanGrantsOwnScans = false,
    allScanGrantsResponsibleCompanies = false,
    allMgmtGrantsOwnScans = false,
    allMgmtGrantsResponsibleCompanies = false,
    responsibleForGroupCompanies = false
) {
    const userFrame = {}
    userFrame[grants.LOCATE_RISK_ADMIN] = locateRiskAdmin
    userFrame[grants.ORGANIZATIONAL_ADMIN] = organizationalAdmin
    userFrame[grants.RESPONSIBLE_FOR_ALL_CUSTOMERS_OF_COMPANY] = responsibleForAllCustomersOfCompany
    userFrame[grants.ALL_COMPANY_GRANTS_ON_OWN_COMPANY] = allCompanyGrantsOwnCompany
    userFrame[grants.ALL_COMPANY_GRANTS_ON_RESPONSIBLE_COMPANIES] = allCompanyGrantsResponsibleCompanies
    userFrame[grants.ALL_USER_GRANTS_ON_OWN_COMPANY] = allUserGrantsOwnCompany
    userFrame[grants.ALL_USER_GRANTS_ON_RESPONSIBLE_COMPANIES] = allUserGrantsResponsibleCompanies
    userFrame[grants.ALL_TASK_GRANTS_ON_OWN_COMPANY] = allTaskGrantsOwnTasks
    userFrame[grants.ALL_TASK_GRANTS_ON_RESPONSIBLE_COMPANIES] = allTaskGrantsResponsibleCompanies
    userFrame[grants.CAN_VIEW_ALL_TASKS_FROM_OWN_COMPANY] = canViewAllTasksOwnCompany
    userFrame[grants.CAN_VIEW_ALL_TASKS_FROM_RESPONSIBLE_COMPANIES] = canViewAllTasksResponsibleCompanies
    userFrame[grants.ALL_SCAN_GRANTS_ON_OWN_COMPANY] = allScanGrantsOwnScans
    userFrame[grants.ALL_SCAN_GRANTS_ON_RESPONSIBLE_COMPANIES] = allScanGrantsResponsibleCompanies
    userFrame[grants.ALL_MGMT_GRANTS_ON_OWN_COMPANY] = allMgmtGrantsOwnScans
    userFrame[grants.ALL_MGMT_GRANTS_ON_RESPONSIBLE_COMPANIES] = allMgmtGrantsResponsibleCompanies
    userFrame[grants.RESPONSIBLE_FOR_GROUP_COMPANIES] = responsibleForGroupCompanies

    return userFrame
}
