const actionsTable = {
    IS_LOCATERISK_ADMIN: 1,

    CHECK_SCAN_CONSENT: 50,
    MODIFY_GENERAL_PRICES: 51,
    MODIFY_COMPANY_PRICES: 52,
    CREATE_LOCATE_RISK_ADMIN: 60,
    CREATE_ORGANIZATIONAL_ADMIN: 61,
    MODIFY_USER_PASSWORDS: 62,
    MODIFY_RISK_LISTS: 63,
    CREATE_UNVALIDATED_TASKS: 64,
    CREATE_UNVALIDATED_MGMT: 65,
    VIEW_TRIAL: 66,

    CREATE_COMPANY: 110,
    CREATE_CUSTOMER_COMPANY: 111,
    MODIFY_COMPANY: 112,
    ACCESS_BILLING_INFORMATION: 113,
    UPLOAD_SCAN_CONSENT: 114,
    VIEW_COMPANY: 115,
    DELETE_COMPANY: 116,

    CREATE_USER: 210,
    DELETE_USER: 211,
    MODIFY_USER_GRANTS: 212,
    RESET_USER_PASSWORD: 213,
    UPDATE_USER_SETTINGS: 214,
    VIEW_USERS: 215,

    CREATE_TASK: 310,
    DELETE_TASK: 311,
    VIEW_TASK: 312,
    SHARE_TASK: 313,
    RERATE_TASK: 314,
    DOWNLOAD_TASK: 315,
    MODIFY_TASK_CONFIG: 316,
    VIEW_FILTER_TASK: 317,
    DOWNLOAD_FILTER_TASK: 318,
    VIEW_FILTER_TASK_HOST: 319,

    CREATE_SCAN: 410,
    DELETE_SCAN: 411,
    VIEW_SCAN: 412,
    SHARE_SCAN: 413,
    RERATE_SCAN: 414,
    DOWNLOAD_SCAN: 415,
    MODIFY_SCAN_CONFIG: 416,

    VIEW_MGMT: 512,
    SHARE_MGMT: 513,
    DOWNLOAD_MGMT: 515,

    VIEW_PRODUCT: 612,

    CREATE_VRM: 710,
    VIEW_VRM: 712,

    CREATE_AND_LIST: 800,

    VIEW_QUESTIONNAIRE: 910, // herstellerrechte
    VIEW_SUPPLIER_ANSWERING: 911, // herstellerrechte
    INVITE_TO_QUESTIONNAIRE: 912, // herstellerrechte
    MODIFY_QUESTIONNAIRE: 913, // herstellerrechte

    VIEW_OWN_ANSWERING: 960, // lieferantenrechte
    MODIFY_ANSWERING: 961, // lieferantenrechte

    VIEW_ANSWERING: 990, //pseudogrant, not granted to users! based on VIEW_OWN_ANSWERING and VIEW_SUPPLIER_ANSWERING

    IS_MANUFACTURER: 10000,
    IS_SUPPLIER: 10001
}

module.exports = {
    actions: actionsTable,
    USER_CHANGE_PASSWORD: '10000',
    USER_RESET_PASSWORD: '10001',
    USER_REQUEST_PASSWORD_RESET: '10002'
}
