import Vue from 'vue'

export default {
    setQuestionnaire(state, data) {
        Vue.set(state, 'questionnaire', data)
    },

    setQuestionnaires(state, data) {
        Vue.set(state, 'questionnaires', data)
    },

    setAnswerings(state, data) {
        Vue.set(state.questionnaire, 'answerings', data)
    },

    setQuestionnaireNameFilter(state, data) {
        Vue.set(state.questionnaireListFilters, 'nameFilter', data)
    },

    setQuestionnaireOwnerFilter(state, data) {
        Vue.set(state.questionnaireListFilters, 'ownerFilter', data)
    },

    setAnsweringListFilter(state, data) {
        Vue.set(state, 'answeringListFilters', data)
    },

    setQuestionnaireAnsweringList(state, data) {
        Vue.set(state, 'questionnaireAnsweringList', data)
    },

    updateAnsweringProperty(state, {id, key, value}) {
        if (state.questionnaireAnsweringList) {
            const index = state.questionnaireAnsweringList.answerings.findIndex((x) => x.id == id)
            if (index != -1) {
                Vue.set(state.questionnaireAnsweringList.answerings[index], key, value)
            }
        }
    },

    setPendingAnswerings(state, {total, self, direct}) {
        state.pendingAnswerings.total = total
        state.pendingAnswerings.self = self
        state.pendingAnswerings.direct = direct
    }
}
