// <v-chip v-if="typeof item.formattedScore === 'number'" :color="getColorCodeFromScore(item.score)" 
//      v-guided-tour='{ title: "Score chip", content: "This shows the current score" }'>

import sum from 'hash-sum';

function getStepId(binding) {
    return binding.value.stepId ? sum(binding.value.stepId) : sum(binding.value.title + binding.value.content);
}

const tourElementDirective = {
    // When the bound element is inserted into the DOM
    inserted: function (el, binding, vnode) {
        // to hide -> simply fill no content
        if (!binding.value.content) return;

        // if supplied own stepId use it instead - when in a list
        // and you only want to highlight first element but depending on context like the score value
        // else there would be two tour steps 
        // tmp save the step in vnode.context to retrieve at the bottom
        vnode.context.step = {
            stepId: getStepId(binding),
            target: el,
            content: binding.value.content,
            contentParams: binding.value.contentParams,
            header: { title: binding.value.title, titleParams: binding.value.titleParams },
            before: binding.value.before,
            click: binding.value.click,
            clickOnce: binding.value.clickOnce,
            clickDelay: binding.value.clickDelay,
            clickRequired: binding.value.clickRequired,
            awaitElement: binding.value.awaitElement, // after click before displaying step
            inDialog: binding.value.inDialog,
            priority: binding.value.priority,
            padding: binding.value.padding,
            noScrolling: binding.value.noScrolling,
            scrollContainer: binding.value.scrollContainer,
            fromDirective: true
        };

        if (!binding.value.hideFromTours) {
            vnode.context.$store.dispatch("addSingleTourStep", vnode.context.step);
        }
        
        vnode.context.$store.commit("mountStep", vnode.context.step);
    },
    unbind: function (el, binding, vnode) {
        vnode.context.$store.commit("unmountStep", vnode.context.step);

        // remove steps from tour that are only in a dialog
        if (binding.value.inDialog) {
            vnode.context.$store.commit("removeStepFromRunningTour", getStepId(binding));
        }
    }
}

export default tourElementDirective;