export const CyberRiskAnalysisReadonlyTour = {
    tourName: 'CyberRiskAnalysisReadonlyTour',
    requiresElement: '.guided-tour-cyber-risk-analysis-report-details-button',
    steps: [
        {
            target: '.guided-tour-cyber-risk-analysis-h1', // We're using document.querySelector() under the hood
            content: 'new.translation.views.widget.overviewp_welcome_1.content',
            //brauchen kein wait-for weil die tour erst startet wenns da ist
            /*
            header: {
                title: 'new.translation.views.widget.overviewp_welcome_1.title'
            },
            */
            padding: true
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-details-button',
            content: 'new.translation.views.widget.overviewp_scan_selection_2.content'
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-h1',
            content: 'new.translation.views.widget.scanp_header_3.content',
            navigate: '.guided-tour-cyber-risk-analysis-report-details-button',
            navigateBack: '.guided-tour-cyber-risk-analysis-report-h1 button',
            padding: true
            //brauchen kein waitFor da er wegen der naviagtion eh wartet
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-domain-info-card',
            content: 'new.translation.views.widget.scanp_systems_findings_overview_4.content',
            waitFor: -1 //4ever!
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-scan-info-card-app',
            content: 'new.translation.views.widget.scanp_categories_overview_5.content'
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-score-overview',
            content: 'new.translation.views.widget.scanp_score_overview_6.content'
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-domain-card',
            content: 'new.translation.views.widget.scanp_systems_list_7.content'
        },
        {
            target: '.report-page .guided-tour-finding-list',
            content: 'new.translation.views.widget.scanp_vulnerabilitiy_list_8.content',
            click: '.dialog button.primary--text' // close config dialog
        },
        {
            target: '.report-page .guided-tour-finding-list-id',
            content: 'new.translation.views.widget.scanp_vulnerability_title_9.content',
            click: '.guided-tour-vulnerability-list-click-target',
            clickOnce: true
        },
        {
            target: '.report-page .guided-tour-finding-list-affected-systems',
            content: 'new.translation.views.widget.scanp_vulnerability_affected_systems_10.content'
        },
        {
            target: '.report-page .guided-tour-finding-list-description',
            content: 'new.translation.views.widget.scanp_vulnerability_description_11.content',
            padding: true,
            click: '.dialog button.primary--text' // click because when navigating back
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-host-list',
            content: 'new.translation.views.widget.scanp_system_vulnerability_16.content',
            click: [
                {target: '.guided-tour-vulnerability-list-click-target', once: true}, // make sure list is opened
                {target: '.dialog button.primary--text'}
            ]
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-host-list-entry',
            content: 'new.translation.views.widget.scanp_system_details_17.content',
            click: '.guided-tour-cyber-risk-analysis-report-host-list-entry',
            clickOnce: true
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-host-list-entry-domains',
            content: 'new.translation.views.widget.scanp_system_details_domains_18.content',
            padding: true
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-host-list-entry-network',
            content: 'new.translation.views.widget.scanp_system_details_host_connection_19.content',
            click: '.guided-tour-cyber-risk-analysis-report-host-list-entry-network .v-expansion-panel-header',
            clickOnce: true,
            padding: true
        },
        {
            target: '.guided-tour-cyber-risk-analysis-report-host-list-entry-finding-list',
            content: 'new.translation.views.widget.scanp_system_details_vulnerabilities_list_20.content'
        },

        {
            target: '.guided-tour-cyber-risk-analysis-report-host-list-entry-finding-list .findingRow[data-id="4373607310803684"]',
            content: 'new.translation.views.widget.scanp_additional_applications_22.content'
        },
        /*
       netzwerkgraph lädt lange, lassen ihn weg.
       {
            target: '.guided-tour-cyber-risk-analysis-report-graph',
            content: 'new.translation.views.widget.scanp_network_graph_21.content', //removed, brauchen das popup hier nicht popup beim kompletten netzwerkgraphen, potentiell laaangsam
            click: '.guided-tour-cyber-risk-analysis-report-graph button',
            clickOnce: true,
            popInner: true
        },*/

        {
            target: '.guided-tour-tag-filter-dialog-button',
            content: 'new.translation.views.widget.scanp_filters_28.content',
            click: '.guided-tour-cyber-risk-analysis-report-host-list-entry',
            clickOnce: true,
            clickRequired: true
        },
        {
            target: 'button.guided-tour-icon-bar-download',
            content: 'new.translation.views.widget.scanp_downloads_29.content',
            click: '.dialog .mdi-close'
        },
        {
            target: '.dialog .v-list.guided-tour-download-list',
            content: 'new.translation.views.widget.scanp_downloads_formats_30.content',
            click: '.guided-tour-icon-bar-download',
            inDialog: true,
            popInner: true
            /*clickDelay: 100 // dialogs are a little slower to open*/
        },
        // workaround step to allow navigating backwards which requires an element to be visible
        // also less chaos in navigation (less surprises for then user)

        // den krams haben wir nach hinten geshcoben
        {
            target: '.guided-tour-cyber-risk-analysis-report-h1 button',
            content: 'new.translation.views.widget.scanp_scroll_to_top_23.content'
        }

        /*
       ist in dem dialog hardcoded, da conditional für !isMGMT
       {
            target: '.guided-tour-cyber-risk-analysis-notifications-light',
            content: 'new.translation.views.widget.scanp_monitoring_light_activation_25.content',
            click: '.guided-tour-cyber-risk-analysis-notifications'
        }*/
    ]
}
