export default {
    async loadStandardPricing({ state, commit }) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/pricing/load-standard`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const json = await response.json()
            commit('setStandardPricing', json)
        }
    },

    async loadProductPrice({ state, commit }, params) {
        const response = await fetch(
            `${Window.$apiUrl}/api/rest/pricing/product-price/${params.companyId}/${params.productName}/${params.isMonitoring}/${params.monitoringInterval}`,
            {
                method: 'GET',
                credentials: 'include'
            }
        )
        if (response.ok) {
            const json = await response.json()
            return json
        }
        return null
    },

    async savePricing({ dispatch }, pricing) {
        await fetch(`${Window.$apiUrl}/api/rest/pricing/save`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(pricing)
        })
    },

    async getBookingOverview({ dispatch }) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/pricing/bookings`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const json = await response.json()
            return json
        }
        return []
    },

    async getBookingsForCompany({ dispatch }, { id }) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/pricing/bookings/${id}`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const json = await response.json()
            return json
        }
        return []
    },

    async getBookingsForCompanyOfMonth({ dispatch }, { id, month }) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/pricing/bookings/${id}/${month}`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const json = await response.json()
            return json
        }
        return []
    },

    async book({ dispatch }, payload) {
        const response = await fetch(`${Window.$apiUrl}/api/rest/stripe/`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(payload)
        })
        return response
    }
}
