import i18next from 'i18next'
import $vue from '../../main'

export default {
    showDefaultSnackbar(state, text = '') {
        state.showDefaultSnackbar = true
        state.defaultSnackbarText = text
    },

    hideDefaultSnackbar(state) {
        state.showDefaultSnackbar = false
    },

    setLanguage(state, language) {
        state.language = language === 'en' ? 'gb' : 'de'
    },

    setMultipleRowsExpansion(state, value) {
        state.multipleRowsExpansion = value
    },

    toggleLRAdminElements(state) {
        state.displayLRAdminElements = !state.displayLRAdminElements
    }
}
